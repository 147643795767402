import { useEffect, useState } from "react";
import {
	Col,
	Row,
	Form,
	Divider,
	Space,
	Button,
	Input,
	Tooltip,
	Checkbox,
	Flex,
} from "antd";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";

import dayjs from "dayjs";
import FloatInput from "../../../../providers/FloatInput";
import FloatInputMask from "../../../../providers/FloatInputMask";
import FloatSelect from "../../../../providers/FloatSelect";
import FloatDatePicker from "../../../../providers/FloatDatePicker";

import validateRules from "../../../../providers/validateRules";
import optionBloodType from "../../../../providers/optionBloodType";
import optionLanguage from "../../../../providers/optionLanguage";
import optionBirthPlace from "../../../../providers/optionBirthPlace";

export default function ApplicantFormBasicInfo(props) {
	const { dataCivilStatus, dataNationalities, dataReligion, form } = props;

	// Middle Name
	const [noMiddleName, setNoMiddleName] = useState(false);
	const handleMiddleName = (event) => {
		setNoMiddleName(event.target.checked);
		form.setFieldsValue({ middlename: "" });
	};

	// Birthdate
	const [birthdate, setBirthdate] = useState(null);
	const [age, setAge] = useState(null);

	const onBirthdateChange = (date, dateString) => {
		const dayjsDate = dayjs(date);
		const dayjsDateString = dayjs(dateString);
		setBirthdate(dayjsDateString);
		calculateAge(dayjsDate);
	};

	// Age base on birthdate
	const calculateAge = (date) => {
		const today = dayjs();
		const birthDate = dayjs(date);
		let calculatedAge = today.diff(birthDate, "years");

		if (calculatedAge < 3 || calculatedAge > 110) {
			calculatedAge = null;
		}

		form.setFieldsValue({ age: calculatedAge });
		setAge(calculatedAge);
	};

	// Add Langauge
	const [currentLanguage, setCurrentLanguage] = useState(optionLanguage);
	const [newLanguage, setNewLanguage] = useState("");

	const handleAddLanguage = () => {
		if (newLanguage) {
			setCurrentLanguage((prevOptions) => [
				...prevOptions,
				{ label: newLanguage, value: newLanguage },
			]);
			setNewLanguage("");
		}
	};

	// Add BirthPlace
	const [currentBirthPlace, setCurrentBirthPlace] = useState(optionBirthPlace);
	const [newBirthPlace, setNewBirthPlace] = useState("");

	const handleAddBirthPlace = () => {
		if (newBirthPlace) {
			setCurrentBirthPlace((prevOptions) => {
				const updatedOptions = [
					...prevOptions,
					{ label: newBirthPlace, value: newBirthPlace },
				];
				return updatedOptions.sort((a, b) => a.value.localeCompare(b.value));
			});
			setNewBirthPlace("");
		}
	};

	useEffect(() => {
		form.setFieldsValue({ age: age });
	}, [age]);

	return (
		<>
			<Row gutter={[12, 12]} className="basic-info">
				<Col xs={24} sm={12} md={12} lg={6} xl={6}>
					<Form.Item
						name="firstname"
						rules={[
							validateRules.required,
							validateRules.alphabet_symbol,
							validateRules.trim,
						]}
					>
						<FloatInput
							label="First Name"
							placeholder="First Name"
							required={true}
						/>
					</Form.Item>
				</Col>

				<Col xs={24} sm={24} md={12} lg={6} xl={6}>
					<Flex gap={5}>
						<Col xs={20} sm={20} md={20} lg={18} xl={18} className="p-0">
							<Form.Item
								name="middlename"
								rules={[
									// if noMiddleName is true, then no need to validate required
									{
										required: !noMiddleName,
										message: "Please enter middle name",
									},
									validateRules.alphabet,
									validateRules.trim,
								]}
								style={{ marginBottom: 0 }}
							>
								<FloatInput
									label="Middle Name"
									placeholder="Middle Name"
									required={true}
									disabled={noMiddleName}
								/>
							</Form.Item>
						</Col>
						<Flex vertical className="w-25">
							<Checkbox
								checked={noMiddleName}
								onChange={handleMiddleName}
								style={{ fontSize: "6px" }}
							></Checkbox>
							<p className="font-10px m-0">No Middle Name</p>
						</Flex>
					</Flex>
				</Col>

				<Col xs={24} sm={12} md={12} lg={6} xl={6}>
					<Form.Item
						name="lastname"
						rules={[
							validateRules.required,
							validateRules.alphabet,
							validateRules.trim,
						]}
					>
						<FloatInput
							label="Last Name"
							placeholder="Last Name"
							required={true}
						/>
					</Form.Item>
				</Col>

				<Col xs={24} sm={12} md={12} lg={6} xl={6}>
					<Form.Item
						name="name_ext"
						rules={[validateRules.alphabet_symbol, validateRules.name_suffix]}
					>
						<FloatInput label="Suffix" placeholder="Suffix" />
					</Form.Item>
				</Col>

				<Col xs={24} sm={12} md={12} lg={6} xl={6}>
					<Form.Item
						name="birthplace"
						rules={[
							validateRules.required,
							validateRules.address,
							validateRules.trim,
						]}
					>
						<FloatSelect
							label="Place of Birth"
							placeholder="Place of Birth"
							allowClear
							required={true}
							options={currentBirthPlace}
							dropdownRender={(menu) => (
								<>
									{menu}
									<Divider
										style={{
											margin: "8px 0",
										}}
									/>
									<Space
										style={{
											padding: "0 8px 4px",
										}}
									>
										<Input
											style={{
												border: "1px solid",
											}}
											value={newBirthPlace}
											onChange={(e) => setNewBirthPlace(e.target.value)}
										/>
										<Button
											type="primary"
											icon={<FontAwesomeIcon icon={faPlus} />}
											name="btn-add-birthplace"
											onClick={handleAddBirthPlace}
										>
											Add Birth Place
										</Button>
									</Space>
								</>
							)}
						/>
					</Form.Item>
				</Col>

				<Col xs={24} sm={12} md={12} lg={6} xl={6}>
					<Form.Item
						name="birthdate"
						rules={[
							validateRules.required,
							{
								validator: (_, value) => {
									const today = dayjs();
									const birthdate = dayjs(value);
									const age = today.year() - birthdate.year();

									if (age < 3 && age < 110) {
										return Promise.reject(new Error("Age must be at least 3"));
									}
									return Promise.resolve();
								},
							},
						]}
					>
						<FloatDatePicker
							className="birthdate"
							label="Date of Birth"
							placeholder="Date of Birth"
							required={true}
							onChange={(date, dateString) => {
								onBirthdateChange(dayjs(date), dayjs(dateString));
								setBirthdate(dayjs(dateString));
							}}
							allowClear
							format={{
								format: "MM-DD-YYYY",
								type: "mask",
							}}
						/>
					</Form.Item>
				</Col>

				<Col xs={24} sm={12} md={12} lg={6} xl={6}>
					<Tooltip title="Please select birthdate">
						<Form.Item name="age" rules={[validateRules.number]}>
							<FloatInput
								label="Age"
								placeholder="Age"
								required={true}
								disabled={true}
							/>
						</Form.Item>
					</Tooltip>
				</Col>

				<Col xs={24} sm={12} md={12} lg={6} xl={6}>
					<Form.Item name="gender" rules={[validateRules.required]}>
						<FloatSelect
							required={true}
							label="Sex"
							placeholder="Sex"
							options={[
								{
									label: "Male",
									value: "Male",
								},
								{
									label: "Female",
									value: "Female",
								},
							]}
						/>
					</Form.Item>
				</Col>

				<Col xs={24} sm={12} md={12} lg={6} xl={6}>
					<Form.Item name="language">
						<FloatSelect
							label="Language"
							placeholder="Language"
							multi="multiple"
							allowClear
							options={currentLanguage}
							dropdownRender={(menu) => (
								<>
									{menu}
									<Divider
										style={{
											margin: "8px 0",
										}}
									/>
									<Space
										style={{
											padding: "0 8px 4px",
										}}
									>
										<Input
											style={{
												border: "1px solid",
											}}
											value={newLanguage}
											onChange={(e) => setNewLanguage(e.target.value)}
										/>
										<Button
											type="primary"
											icon={<FontAwesomeIcon icon={faPlus} />}
											name="btn-add-language"
											onClick={handleAddLanguage}
										>
											Add language
										</Button>
									</Space>
								</>
							)}
						/>
					</Form.Item>
				</Col>

				<Col xs={24} sm={12} md={12} lg={6} xl={6}>
					<Form.Item name="blood_type">
						<FloatSelect
							allowClear
							label="Blood Type"
							placeholder="Blood Type"
							options={optionBloodType}
						/>
					</Form.Item>
				</Col>

				<Col xs={24} sm={12} md={12} lg={6} xl={6} className="height-info">
					<Form.Item
						name="height"
						rules={[
							validateRules.height,
							{
								min: 2,
								max: 300,
							},
						]}
					>
						<FloatInputMask
							label="Height (cm)"
							placeholder="Height (cm)"
							maskType="999"
							autoComplete="off"
						/>
					</Form.Item>
				</Col>

				<Col xs={24} sm={12} md={12} lg={6} xl={6} className="weight-info">
					<Form.Item
						name="weight"
						rules={[
							validateRules.weight,
							{
								min: 2,
								max: 300,
							},
						]}
					>
						<FloatInputMask
							label="Weight (kg)"
							placeholder="Weight (kg)"
							maskType="99.9"
						/>
					</Form.Item>
				</Col>

				<Col xs={24} sm={12} md={12} lg={6} xl={6}>
					<Form.Item name="religion_id">
						<FloatSelect
							label="Religion"
							placeholder="Religion"
							allowClear
							options={dataReligion
								.map((item) => ({
									value: item.id,
									label: item.religion,
								}))
								.sort((a, b) => a.label.localeCompare(b.label))}
						/>
					</Form.Item>
				</Col>

				<Col xs={24} sm={12} md={12} lg={6} xl={6}>
					<Form.Item name="nationality_id">
						<FloatSelect
							label="Citizenship"
							placeholder="Citizenship"
							allowClear
							options={dataNationalities
								.map((item) => ({
									value: item.id,
									label: item.nationality,
								}))
								.sort((a, b) => a.label.localeCompare(b.label))}
						/>
					</Form.Item>
				</Col>

				<Col xs={24} sm={12} md={12} lg={6} xl={6}>
					<Form.Item name="civil_status_id">
						<FloatSelect
							label="Civil Status"
							placeholder="Civil Status"
							allowClear
							options={dataCivilStatus
								.map((item) => ({
									value: item.id,
									label: item.civil_status,
								}))
								.sort((a, b) => a.label.localeCompare(b.label))}
						/>
					</Form.Item>
				</Col>

				<Col xs={24} sm={12} md={12} lg={6} xl={6} className="contact-info">
					<Form.Item
						name="contact_number"
						rules={[validateRules.phone, validateRules.required]}
					>
						<FloatInputMask
							label="Contact Number"
							placeholder="Contact Number"
							maskLabel="contact_number"
							required={true}
							maskType="(+63) 999 999 9999"
						/>
					</Form.Item>
				</Col>

				<Col xs={24} sm={24} md={24} lg={6} xl={6}>
					<Form.Item
						name="personal_email"
						rules={[validateRules.required, validateRules.email]}
					>
						<FloatInput label="Email" placeholder="Email" required />
					</Form.Item>
				</Col>
			</Row>
		</>
	);
}
