import { Modal, Button, Typography, Flex, Image } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { apiUrl } from "../../../../providers/companyInfo";
import { GET } from "../../../../providers/useAxiosQuery";

export default function ModalProfile(props) {
	const { toggleModalProfile } = props;

	const navigate = useNavigate();
	const location = useLocation();

	const { data: dataStudentQrCode } = GET(`api/student_exams`, "student_exams");

	const qrFilePath = dataStudentQrCode?.data
		?.filter((item) => item.username === toggleModalProfile.username)
		.map((exam) => exam.qr_file_path);

	// console.log("qrFilePath: ", qrFilePath);

	return (
		<Modal
			className="modal-profiling-portal"
			open={toggleModalProfile.open}
			forceRender
			footer={[
				<Flex justify="center">
					<Button
						className="btn-main-primary"
						type="primary"
						key={2}
						onClick={() => navigate("/profiling-portal")}
					>
						Done
					</Button>
				</Flex>,
			]}
		>
			<Flex className="mt-30" vertical>
				<img
					src={
						location.pathname.includes("/profiling-portal/add")
							? "./../assets/images/success_astronaut.gif"
							: "./../../assets/images/success_astronaut.gif"
					}
					alt="fsuu_logo"
					width={150}
				/>
			</Flex>

			<Typography.Title level={4} color="blue">
				Your application has been successfully submitted!
			</Typography.Title>

			<Image alt="QR Code" src={apiUrl(qrFilePath)} height={150} />

			<p style={{ textAlign: "center", fontSize: "13px" }}>
				<strong>Note</strong>: The QR code will be sent to your email along with
				further instructions.
			</p>

			<em style={{ textAlign: "center", fontSize: "14px" }}>
				<span> </span>Please take note of your username, "
				<strong style={{ fontSize: "16px" }}>
					{toggleModalProfile.username}
				</strong>
				" to verify and update your information for future exam scheduling. You
				may now proceed to profile verification. Thank you!
				<br />
			</em>

			{/* <em style={{ textAlign: "center", fontSize: "14px" }}>
				<span> </span>Please take note of your username, "
				<strong style={{ fontSize: "16px" }}>{toggleModalProfile.username}</strong>
				" to verify and update your information for future exam scheduling. Then
				proceed to profile verification. Thank you!"
			</em> */}
		</Modal>
	);
}
