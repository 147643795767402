import Highcharts from "highcharts";
import highchartsSetOptions from "../../../providers/highchartsSetOptions";
import React from "react";
import { CustomerCard } from "../../../providers/CustomerCard";

require("highcharts/modules/accessibility")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/boost")(Highcharts);

export default function PageDashboard() {
	highchartsSetOptions(Highcharts);

	return (
		<div>
			<CustomerCard />
		</div>
	);
}
