import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Tabs, Row, Col } from "antd/lib";

import { GET } from "../../../../providers/useAxiosQuery";
import PageExamCategory from "../PageExamCategory/PageExamCategory";
import PageUser from "../PageUser/PageUser";
import ModalAuthPassword from "./ModalAuthPassword";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListOl, faUser } from "@fortawesome/pro-regular-svg-icons";

export default function PageSettings() {
	const location = useLocation();
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);

	const [toggleModalAuthPassword, setToggleModalAuthPassword] = useState({
		open: false,
		data: null,
	});

	const [tableFilter, setTableFilter] = useState({
		page: 1,
		page_size: 50,
		search: "",
		status: "Active",
		department_id: "",
		isTrash: 0,
		user_role_id: 1,
	});

	useEffect(() => {
		setTableFilter({
			page: 1,
			page_size: 50,
			search: "",
			status: "Active",
			isTrash: 0,
			user_role_id: 1,
		});

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	const { data: dataUser, refetch: refetchSourceUser } = GET(
		`api/users?${new URLSearchParams(tableFilter)}`,
		"users_list"
	);

	const { data: dataExamCategory, refetch: refetchSourceExamCategory } = GET(
		`api/exam_category?${new URLSearchParams(tableFilter)}`,
		"exam_category_list"
	);

	return (
		<>
			<Tabs
				className="TABS"
				defaultActiveKey="1"
				indicator={{
					size: (origin) => origin - 25,
					align: "end",
				}}
				items={[
					{
						icon: <FontAwesomeIcon icon={faUser} />,
						label: "User",
						key: "1",
						children: (
							<Row gutter={[12, 12]}>
								<Col xs={24} sm={24} md={24}>
									<PageUser
										tableFilter={tableFilter}
										setTableFilter={setTableFilter}
										selectedRowKeys={selectedRowKeys}
										setSelectedRowKeys={setSelectedRowKeys}
										ModalAuthPassword={ModalAuthPassword}
										dataUser={dataUser}
										refetchSourceUser={refetchSourceUser}
									/>
								</Col>
							</Row>
						),
					},
					{
						icon: <FontAwesomeIcon icon={faListOl} />,
						label: "Exam Category",
						key: "2",
						children: (
							<Row gutter={[12, 12]}>
								<Col xs={24} sm={24} md={24}>
									<PageExamCategory
										tableFilter={tableFilter}
										setTableFilter={setTableFilter}
										selectedRowKeys={selectedRowKeys}
										setSelectedRowKeys={setSelectedRowKeys}
										dataExamCategory={dataExamCategory}
										refetchSourceExamCategory={refetchSourceExamCategory}
									/>
								</Col>
							</Row>
						),
					},
				]}
			/>

			<ModalAuthPassword
				toggleModalAuthPassword={toggleModalAuthPassword}
				setToggleModalAuthPassword={setToggleModalAuthPassword}
			/>
		</>
	);
}
