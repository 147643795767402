import React, { useState } from "react";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;

const FloatRangePicker = (props) => {
	const {
		label,
		value,
		placeholder,
		required,
		popupClassName,
		format,
		picker,
		disabled,
		disabledDate,
		id,
		onChange,
		allowClear,
		className,
		size,
		onBlur,
	} = props;

	const [focus, setFocus] = useState(false);

	let new_placeholder = !placeholder ? label : placeholder;

	let isOccupied = focus || (value && value.length !== 0);

	let labelClass = isOccupied ? "label float-label" : "label";

	let requiredMark = required ? <span className="text-danger">*</span> : null;

	return (
		<div
			id={id ?? ""}
			className={`float-wrapper float-range-picker ${className ?? ""}`}
			onBlur={() => setFocus(false)}
			onFocus={() => setFocus(true)}
		>
			<RangePicker
				onChange={onChange}
				onBlur={onBlur}
				value={value ? value : null}
				size={size ?? "large"}
				placeholder={[""]}
				popupClassName={popupClassName ?? ""}
				format={format ? format : "YYYY"}
				allowClear={allowClear ?? false}
				picker={picker ? picker : "year"}
				disabled={disabled ? disabled : false}
				disabledDate={(current) => {
					if (disabledDate) {
						return disabledDate(current);
					} else {
						return false;
					}
				}}
			/>

			<label className={labelClass}>
				{isOccupied ? label : new_placeholder} {requiredMark}
			</label>
		</div>
	);
};

export default FloatRangePicker;
