import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
	Col,
	Row,
	Form,
	Typography,
	notification,
	Button,
	Tooltip,
} from "antd";

import { GET, POST } from "../../../../providers/useAxiosQuery";
import FloatInput from "../../../../providers/FloatInput";
import FloatSelect from "../../../../providers/FloatSelect";
import FloatTextArea from "../../../../providers/FloatTextArea";

import notificationErrors from "../../../../providers/notificationErrors";
import validateRules from "../../../../providers/validateRules";
import ModalAuthPassword from "../components/ModalAuthPassword";

import ApplicantFormParentInfo from "./ApplicantFormParentInfo";

export default function ApplicantFormFamilyProfile(props) {
	const { formDisabled, profile_id, action } = props;

	const params = useParams();
	const [form] = Form.useForm();
	const [formData, setFormData] = useState(null);
	const [dataProvince, setDataProvince] = useState([]);
	const [dataMunicipality, setDataMunicipality] = useState([]);
	const [dataBarangay, setDataBarangay] = useState([]);

	const [toggleModalAuthPassword, setToggleModalAuthPassword] = useState({
		open: false,
		data: null,
	});

	const { data: dataRegions } = GET(
		`api/region_dropdown`,
		"region_select",
		(res) => {},
		false
	);

	const { refetch: refetchFamilyProfile } = GET(
		`api/student_exams/${profile_id}`,
		["student_exams_info", "check_user_permission"],
		(res) => {
			if (res.data) {
				let data = res.data;
				console.log("data: ", data);

				let number_of_brothers = data.number_of_brothers;
				let number_of_sisters = data.number_of_sisters;
				let birth_rank = data.birth_rank;

				// Address List
				let address = null;
				let zip_code = null;

				let barangay_id = null;
				let municipality_id = null;
				let province_id = null;
				let region_id = null;

				if (data.profile_addresses && data.profile_addresses.length > 0) {
					data.profile_addresses
						.filter((item) => item.category === "FAMILY ADDRESS")
						.map((item) => {
							barangay_id = item.barangay_id;
							municipality_id = item.city_id;
							address = item.address;
							zip_code = item.zip_code;

							if (
								dataRegions &&
								dataRegions.data &&
								dataRegions.data.length > 0
							) {
								dataRegions.data.forEach((region) => {
									region.ref_provinces.forEach((province) => {
										province.ref_municipalities.forEach((municipality) => {
											if (municipality.id === item.city_id) {
												region_id = region.id;
												province_id = province.id;
											}
										});
									});
								});
							}
						});
				}

				// Parent List
				let parent_list = [{}];

				if (
					data.profile_parent_informations &&
					data.profile_parent_informations.length > 0
				) {
					parent_list = data.profile_parent_informations
						.filter(
							(item) =>
								item.category === "FAMILY PROFILE" && item.status === "1"
						)
						.map((item) => {
							return {
								...item,
							};
						});
				}

				form.setFieldsValue({
					number_of_brothers,
					number_of_sisters,
					birth_rank,

					address,
					zip_code,
					barangay_id,
					municipality_id,
					province_id,
					region_id,

					parent_list: parent_list,
				});
			}
		},
		false
	);

	useEffect(() => {
		refetchFamilyProfile();
	}, [profile_id, refetchFamilyProfile]);

	const {
		mutate: mutateFamilyProfileUpdate,
		isLoading: isLoadingUpdateChanges,
	} = POST(`api/update_family_profile`, "student_exams_list");

	const onFinish = (auth_password) => {
		let values = formData;

		let data = {
			...values,
			// parent_list,
			profile_id: parseInt(profile_id, 10),

			// Auth Password Address for Changes
			auth_password: auth_password,
		};

		mutateFamilyProfileUpdate(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "Family Profile Updated",
						description: res.message,
					});
					setToggleModalAuthPassword({
						open: false,
						data: null,
					});
				} else {
					notification.success({
						message: "Family Profile Updated",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	return (
		<>
			<Form
				form={form}
				onFinish={onFinish}
				initialValues={{ address_list: [{}], parent_list: [{}] }}
			>
				<Col
					xs={24}
					sm={24}
					md={24}
					lg={24}
					xl={24}
					className="tab-item-parent-info"
				>
					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Typography.Title level={5} type="secondary" className="mt-0">
							Parent
						</Typography.Title>
					</Col>
					<ApplicantFormParentInfo
						formDisabled={formDisabled}
						form={form}
						action={action}
						profile_id={params.id}
					/>
				</Col>

				<Row gutter={[18, 0]}>
					{/* Number of Siblings */}

					<Col xs={24} sm={24} md={6} lg={6} xl={6}>
						<Form.Item name="birth_rank" rules={[validateRules.number]}>
							<FloatInput
								label="Birth Rank"
								placeholder="Birth Rank"
								disabled={formDisabled}
							/>
						</Form.Item>
					</Col>
					<Col xs={24} sm={24} md={6} lg={6} xl={6}>
						<Form.Item name="number_of_brothers" rules={[validateRules.number]}>
							<FloatInput
								label="Number of Brother"
								placeholder="Number of Brother"
								disabled={formDisabled}
							/>
						</Form.Item>
					</Col>

					<Col xs={24} sm={24} md={6} lg={6} xl={6}>
						<Form.Item name="number_of_sisters" rules={[validateRules.number]}>
							<FloatInput
								label="Number of Sister"
								placeholder="Number of Sister"
								disabled={formDisabled}
							/>
						</Form.Item>
					</Col>

					<Col xs={24} sm={24} md={6} lg={6} xl={6}></Col>

					<Col xs={24} sm={12} md={12} lg={6} xl={6}>
						<Form.Item
							name="region_id"
							// rules={[validateRules.quillValidator]}
						>
							<FloatSelect
								label="Region"
								placeholder="Region"
								allowClear
								required={true}
								options={
									dataRegions && dataRegions.data
										? dataRegions.data.map((item) => ({
												value: item.id,
												label: item.region,
										  }))
										: []
								}
								onChange={(e) => {
									let provinces = [];
									if (e) {
										let region = dataRegions?.data?.find((f) => f.id === e);

										provinces = region.ref_provinces;
									}
									setDataProvince(provinces);
									setDataMunicipality([]);
									setDataBarangay([]);
								}}
								disabled={formDisabled}
							/>
						</Form.Item>
					</Col>

					<Col xs={24} sm={12} md={12} lg={6} xl={6}>
						<Tooltip
							title={
								!form.getFieldValue("region_id") ? "Please select Region" : ""
							}
						>
							<Form.Item
								name="province_id"
								//  rules={[validateRules.quillValidator]}
							>
								<Form.Item name="province_id">
									<FloatSelect
										label="Province"
										placeholder="Province"
										allowClear
										required={true}
										options={
											dataProvince
												? dataProvince.map((item) => ({
														value: item.id,
														label: item.province,
												  }))
												: []
										}
										onChange={(e) => {
											let municipalities = [];
											if (e) {
												let province = dataProvince.find((f) => f.id === e);

												municipalities = province.ref_municipalities;
											}

											setDataMunicipality(municipalities);
											setDataBarangay([]);
										}}
										disabled={!form.getFieldValue("region_id")}
									/>
								</Form.Item>
							</Form.Item>
						</Tooltip>
					</Col>

					<Col xs={24} sm={12} md={12} lg={6} xl={6}>
						<Tooltip
							title={
								!form.getFieldValue("province_id")
									? "Please select Province"
									: ""
							}
						>
							<Form.Item
								name="municipality_id"
								// rules={[validateRules.quillValidator]}
							>
								<FloatSelect
									label="Municipality"
									placeholder="Municipality"
									allowClear
									required={true}
									options={dataMunicipality.map((item) => ({
										value: item.id,
										label: item.municipality,
									}))}
									onChange={(e) => {
										let barangays = [];
										if (e) {
											let municipality = dataMunicipality.find(
												(f) => f.id === e
											);

											barangays = municipality.ref_barangays;
										}

										setDataBarangay(barangays);
									}}
									disabled={!form.getFieldValue("province_id")}
								/>
							</Form.Item>
						</Tooltip>
					</Col>

					<Col xs={24} sm={12} md={12} lg={6} xl={6}>
						<Tooltip
							title={
								!form.getFieldValue("municipality_id")
									? "Please select Municipality"
									: ""
							}
						>
							<Form.Item
								name="barangay_id"
								// rules={[validateRules.quillValidator]}
							>
								<FloatSelect
									label="Barangay"
									placeholder="Barangay"
									allowClear
									required={true}
									options={dataBarangay.map((item) => ({
										value: item.id,
										label: item.barangay,
									}))}
									disabled={!form.getFieldValue("municipality_id")}
								/>
							</Form.Item>
						</Tooltip>
					</Col>

					<Col xs={24} sm={24} md={24} lg={18} xl={18}>
						<Form.Item
							name="address"
							rules={[
								// validateRules.quillValidator,
								validateRules.address,
								validateRules.trim,
								{
									min: 5,
									message: "Please enter at least 5 characters",
								},
							]}
						>
							<FloatTextArea
								label="Address"
								placeholder="Address"
								required={true}
								disabled={formDisabled}
							/>
						</Form.Item>
					</Col>

					<Col xs={24} sm={24} md={6} lg={6} xl={6}>
						<Form.Item
							name="zip_code"
							rules={[
								validateRules.quillValidator,
								validateRules.number,
								validateRules,
							]}
						>
							<FloatInput
								label="Zip Code"
								placeholder="Zip Code"
								disabled={formDisabled}
								required={true}
							/>
						</Form.Item>
					</Col>

					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Button
							type="primary"
							className="btn-main-primary mb-20 w-xs-100"
							onClick={() => {
								setFormData(form.getFieldsValue());
								setToggleModalAuthPassword({
									open: true,
								});
							}}
						>
							Change
						</Button>
					</Col>
				</Row>

				{/* Modal */}
				<ModalAuthPassword
					toggleModalAuthPassword={toggleModalAuthPassword}
					setToggleModalAuthPassword={setToggleModalAuthPassword}
					onFinish2={onFinish}
					profile_id={profile_id}
					isLoadingUpdateChanges={isLoadingUpdateChanges}
				/>
			</Form>
		</>
	);
}
