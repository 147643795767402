import { useEffect } from "react";
import { Modal, Button, Form, notification } from "antd";

import { POST } from "../../../../providers/useAxiosQuery";
import FloatInputPassword from "../../../../providers/FloatInputPassword";
import validateRules from "../../../../providers/validateRules";
import notificationErrors from "../../../../providers/notificationErrors";
import { useLocation } from "react-router-dom";

export default function ModalAuthPassword(props) {
	const {
		toggleModalAuthPassword,
		setToggleModalAuthPassword,
		setToggleModalFormProfile,
		onFinish2,
		refetchStudentExam,
	} = props;

	// console.log("toggleModalAuthPassword: ", toggleModalAuthPassword);
	console.log(
		"third toggle toggleModalAuthPassword: ",
		toggleModalAuthPassword
	);

	const [form] = Form.useForm();
	const location = useLocation();

	const {
		mutate: mutateMultipleApplicantAuth,
		isLoading: isLoadingMultipleApplicantAuth,
	} = POST(`api/applicant_auth`, "student_exams_list");

	const onFinish = (values) => {
		let newValues = {};
		const { tableFilter, data } = toggleModalAuthPassword;
		const tabActive = tableFilter?.tabActive;

		console.log("tabActive: ", tabActive);

		if (tabActive === "Payment Approval List View") {
			const formData = new FormData();

			const dataLoad = {
				...values,
				newValues: data,
				from: tabActive,
				link_origin: window.location.origin,
			};
			mutateMultipleApplicantAuth(dataLoad, {
				onSuccess: (res) => {
					notification.success({
						message: "Success",
						description: res.message,
					});
					setToggleModalAuthPassword({ open: false, data: null });
					form.resetFields();
					refetchStudentExam();
				},
				onError: notificationErrors,
			});
		} else if (tabActive === "Exam Status List View") {
			newValues = data?.map(
				({ id, fullname, exam_sheet_number, exam_status }) => ({
					id,
					fullname: fullname ?? null,
					exam_sheet_number: exam_sheet_number ?? null,
					exam_status: exam_status ?? null,
				})
			);

			const payload = {
				...values,
				newValues,
				from: tabActive,
				link_origin: window.location.origin,
			};
			mutateMultipleApplicantAuth(payload, {
				onSuccess: (res) => {
					if (res.success) {
						notification.success({
							message: "Success",
							description: res.message,
						});
						setToggleModalAuthPassword({ open: false, data: null });
						form.resetFields();
					} else {
						notification.error({
							message: "Profile Verification",
							description: res.message,
						});
					}
				},
				onError: notificationErrors,
			});
		} else if (tabActive === "Exam Result List View") {
			const formData = new FormData();
			formData.append("exam_category_id", parseInt(data?.exam_category_id, 10));
			if (data?.file_excel?.[0]?.originFileObj) {
				formData.append("file_excel", data.file_excel[0].originFileObj);
			}
			formData.append("from", "Exam Result List View");
			formData.append("link_origin", window.location.origin);
			formData.append("auth_password", values.auth_password);

			mutateMultipleApplicantAuth(formData, {
				onSuccess: (res) => {
					notification.success({
						message: "Success",
						description: res.message,
					});
					setToggleModalAuthPassword({ open: false, data: null });
					form.resetFields();
				},
				onError: notificationErrors,
			});
		} else if (
			toggleModalAuthPassword.from === "Profile Validation List View"
		) {
			newValues = {
				...data,
				exam_schedule_id: toggleModalAuthPassword.exam_schedule_id,
			};

			const dataLoad = {
				...values,
				newValues,
				from: toggleModalAuthPassword.from,
				link_origin: window.location.origin,
			};

			mutateMultipleApplicantAuth(dataLoad, {
				onSuccess: (res) => {
					notification.success({
						message: "Success",
						description: res.message,
					});
					{
						!location.pathname.includes("applicants") &&
							setToggleModalFormProfile({ open: false, data: null });
					}
					setToggleModalAuthPassword({ open: false, data: null });
					form.resetFields();
					refetchStudentExam();
				},
				onError: notificationErrors,
			});
		} else {
			onFinish2(values.auth_password);
		}
	};

	useEffect(() => {
		if (toggleModalAuthPassword.open) {
			form.setFieldsValue({ ...toggleModalAuthPassword.data });
		}
	}, [toggleModalAuthPassword, form]);

	return (
		<Modal
			title="Confirm Changes"
			open={toggleModalAuthPassword.open}
			onCancel={() => {
				setToggleModalAuthPassword({ open: false, data: null });
				form.resetFields();
			}}
			forceRender
			footer={[
				<Button
					className="btn-main-primary outlined"
					key={1}
					onClick={() => {
						setToggleModalAuthPassword({ open: false, data: null });
						form.resetFields();
					}}
				>
					CANCEL
				</Button>,
				<Button
					className="btn-main-primary"
					htmlType="submit"
					type="primary"
					key={2}
					loading={isLoadingMultipleApplicantAuth}
					onClick={() => form.submit()}
				>
					SUBMIT
				</Button>,
			]}
		>
			<Form form={form} onFinish={onFinish}>
				<Form.Item name="auth_password" rules={[validateRules.quillValidator]}>
					<FloatInputPassword
						label="Password"
						placeholder="Password"
						required
					/>
				</Form.Item>
			</Form>
		</Modal>
	);
}
