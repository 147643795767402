import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Row, Col, Form, notification, Card, Button, Flex, QRCode } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import { GET, POST } from "../../../providers/useAxiosQuery";
import { apiUrl } from "../../../providers/companyInfo";
import notificationErrors from "../../../providers/notificationErrors";
import convertQrCodeToImage from "../../../providers/convertQrCodeToImage";

import ApplicantFormBasicInfo from "./PageApplicantFormComponent/ApplicantFormBasicInfo";
import ApplicantFormExamInfo from "./PageApplicantFormComponent/ApplicantFormExamInfo";
import ApplicantFormPhotoInfo from "./PageApplicantFormComponent/ApplicantFormPhotoInfo";
import ApplicantFormAddressInfo from "./PageApplicantFormComponent/ApplicantFormAddressInfo";
import ApplicantFormHealthInfo from "./PageApplicantFormComponent/ApplicantFormHealthInfo";

import ModalProfile from "../PageProfilingPortal/components/ModalProfile"; // Show Proceed to Verification
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";

export default function PageApplicantFormAdd() {
	const navigate = useNavigate();
	const params = useParams();
	const location = useLocation();
	const [form] = Form.useForm();

	const [dataSource, setDataSource] = useState(null);
	const [qrCode, setQrCode] = useState("");
	const [selectedStudentLevel, setSelectedStudentLevel] = useState();

	const [toggleModalProfile, setToggleModalProfile] = useState({
		open: false,
		data: null,
	});

	const [
		toggleModalUploadProfilePicture,
		setToggleModalUploadProfilePicture,
	] = useState({
		open: false,
		data: null,
		file: null,
		src: null,
		is_camera: null,
		fileName: null,
	});

	const { data: dataCivilStatus } = GET(
		`api/civilstatus`,
		"civilstatus",
		(res) => {},
		false
	);

	const { data: dataNationalities } = GET(
		`api/nationality`,
		"nationality",
		(res) => {},
		false
	);

	const { data: dataReligion } = GET(`api/religion`, "religion");

	const { data: dataExamSchedule } = GET(
		`api/exam_schedule`,
		"exam_schedule_list",
		(res) => {
			if (res.data) {
				let data = res.data;

				if (params && params.id) {
					let find = data.find((x) => x.id === Number(params.id));

					if (find) {
						let formattedDate = dayjs(find.exam_date).format("MMMM DD, YYYY");
						let examSchedule = `${formattedDate} (${find.time_in} ${find.time_in_meridiem} - ${find.time_out} ${find.time_out_meridiem})`;

						let brExamSchedule = document.querySelector(".brExamSchedule");

						if (brExamSchedule) {
							brExamSchedule.innerHTML = examSchedule;
						}

						form.setFieldValue("exam_schedule_id", find.id);
					}
				}
			}
		},
		false
	);

	const { data: dataStudentQr, refetch: refetchStudentQr } = GET(
		`api/student_exams`,
		"student_exams",
		(res) => {},
		false
	);

	const qrFilePath = dataStudentQr?.data?.map((exam) => exam.qr_file_path);

	const { mutate: mutateStudentCreate, isLoading: isLoadingStudent } = POST(
		`api/student_exams`,
		"student_exams_list"
	);

	const capitalizeWords = (str) =>
		str
			.split(" ")
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
			.join(" ");

	const handleStudentLevelChange = (value) => {
		setSelectedStudentLevel(parseInt(value, 10));
	};

	const onFinish = async (values) => {
		let data = new FormData();
		data.append("id", params.id ? params.id : "");

		if (values.user_role_id === 4) {
			let response = await fetch(`/api/generate_school_id/student`);
			let school_id = await response.text();

			data.append("school_id", school_id);
		}

		// Add Profile Picture
		if (toggleModalUploadProfilePicture.file) {
			data.append(
				"profile_picture",
				toggleModalUploadProfilePicture.file,
				toggleModalUploadProfilePicture.fileName
			);
		}

		// Add Account Information
		const username = (
			values.firstname.split(" ")[0] +
			(values.middlename ? values.middlename.substring(0, 1) : "") +
			values.lastname
		)
			.replace(/\s+/g, "")
			.toLowerCase();

		data.append("username", username);

		data.append(
			"email",
			(
				values.firstname.split(" ")[0] +
				(values.middlename ? values.middlename.substring(0, 1) : "") +
				values.lastname
			)
				.replace(/\s+/g, "")
				.toLowerCase() + "@urios.edu.ph"
		);
		data.append(
			"password",
			values.lastname
				.split(" ")
				.map(
					(word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
				)
				.join("")
				.replace(/\s+/g, "")
		);

		// Add Basic Information
		const fieldsToCapitalize = [
			"firstname",
			"middlename",
			"lastname",
			"birthplace",
		];
		fieldsToCapitalize.forEach((field) => {
			if (values[field]) {
				data.append(field, capitalizeWords(values[field]));
			}
		});

		if (values.name_ext) {
			data.append("name_ext", values.name_ext);
		}

		data.append("birthdate", dayjs(values.birthdate).format("YYYY-MM-DD"));
		data.append("age", values.age);

		if (values.gender) {
			data.append("gender", values.gender);
		}

		if (values.language) {
			values.language.forEach((id, index) => {
				data.append(`language[${index}]`, id);
			});
		}

		if (values.blood_type) {
			data.append("blood_type", values.blood_type);
		}
		if (values.height) {
			data.append("height", values.height);
		}
		if (values.weight) {
			data.append("weight", values.weight);
		}

		// data.append(
		// 	"contact_number",
		// 	values.contact_number ? values.contact_number.split(" ").join("") : ""
		// );

		data.append("contact_number", values.contact_number);

		if (values.religion_id) {
			data.append("religion_id", values.religion_id);
		}

		if (values.civil_status_id) {
			data.append("civil_status_id", values.civil_status_id);
		}

		if (values.nationality_id) {
			data.append("nationality_id", values.nationality_id);
		}

		data.append("personal_email", values.personal_email);

		// Add Health Information
		if (values.have_difficulty) {
			data.append("have_difficulty", values.have_difficulty);
		}
		if (values.difficulty_type) {
			data.append("difficulty_type", values.difficulty_type);
		}
		if (values.other_difficulty) {
			data.append("other_difficulty", values.other_difficulty);
		}
		if (values.have_disability) {
			data.append("have_disability", values.have_disability);
		}
		if (values.disability_type) {
			data.append("disability_type", values.disability_type);
		}
		if (values.other_disability) {
			data.append("other_disability", values.other_disability);
		}

		// Add Address Information
		if (values.address_list) {
			values.address_list.forEach((address, index) => {
				Object.keys(address).forEach((key) => {
					data.append(`address_list[${index}][${key}]`, address[key]);
				});
			});
		}

		let formTemp = Array.isArray(form.getFieldValue("address_list"))
			? form.getFieldValue("address_list")
			: [];

		let homeAddressSelected = formTemp.some((item) => item.is_home_address);
		let currentAddressSelected = formTemp.some(
			(item) => item.is_current_address
		);

		if (!homeAddressSelected) {
			notification.error({
				message: "Personal Information",
				description: "Please select a home address.",
			});
			return;
		}

		if (!currentAddressSelected) {
			notification.error({
				message: "Personal Information",
				description: "Please select a current address.",
			});
			return;
		}

		// Add Entrance Exam Information
		if (values.student_level_id) {
			data.append(
				"student_level_id",
				values.student_level_id ? values.student_level_id : null
			);
		}
		if (values.current_course_id) {
			data.append("current_course_id", values.current_course_id);
		}
		if (values.student_strand) {
			data.append("student_strand", values.student_strand);
		}

		if (values.exam_category_id) {
			data.append("exam_category_id", values.exam_category_id);
		}
		if (values.student_status) {
			data.append("student_status", values.student_status);
		}

		if (values.data_consent) {
			data.append("data_consent", values.data_consent);
		}
		if (values.scholarship) {
			data.append("scholarship", values.scholarship);
		}

		let pathSegments = location.pathname.split("/");

		// --- SCHOLARSHIP --- //
		if (values.apply_scholarship) {
			data.append("apply_scholarship", values.apply_scholarship);
		}

		// if (values.scholarship_id) {
		// 	data.append("scholarship_id", values.scholarship_id);
		// }

		if (pathSegments.length !== 5) {
			data.append("exam_schedule_id", values.exam_schedule_id);
		} else {
			data.append("exam_schedule_id", pathSegments[3]);
		}

		// --- COURSES --- //
		if (values.exam_category_id !== 3) {
			if (values.first_course_id) {
				data.append("first_course_id", values.first_course_id);
			}
			if (values.second_course_id) {
				data.append("second_course_id", values.second_course_id);
			}
			if (values.third_course_id) {
				data.append("third_course_id", values.third_course_id);
			}
		}

		// --- TRANSFEREE--- //
		if (values.student_status === "Transferee") {
			if (values.previous_school_name) {
				data.append("previous_school_name", values.previous_school_name);
			}
			if (values.previous_school_year) {
				data.append(
					"previous_school_year",
					dayjs(values.previous_school_year).format("YYYY")
				);
			}
			if (values.applied_to_fsuu) {
				data.append("applied_to_fsuu", values.applied_to_fsuu);
			}
			if (values.year_applied) {
				data.append("year_applied", dayjs(values.year_applied).format("YYYY"));
			}
			if (values.accepted_to_fsuu) {
				data.append("accepted_to_fsuu", values.accepted_to_fsuu);
			}
			if (values.year_accepted) {
				data.append(
					"year_accepted",
					dayjs(values.year_accepted).format("YYYY")
				);
			}
			if (values.attended_to_fsuu) {
				data.append("attended_to_fsuu", values.attended_to_fsuu);
			}
			if (values.year_attended) {
				data.append(
					"year_attended",
					dayjs(values.year_attended).format("YYYY")
				);
			}
		}

		// --- GENERATE QR --- //
		if (location.pathname.includes("/profiling-portal")) {
			let qrCodeFile = await convertQrCodeToImage(
				"myqrcode",
				"applicant_qr_code.png"
			);

			data.append("qr_code_file", qrCodeFile);
		}

		data.append("link_origin", process.env.REACT_APP_STUDENT_URL);

		// Notification
		mutateStudentCreate(data, {
			onSuccess: (res) => {
				if (res.success) {
					if (location.pathname !== "/profiling-portal/add") {
						notification.success({
							message: "Applicant Basic Information",
							description: res.message,
						});
					}
				} else {
					notification.error({
						message: "Applicant Basic Information",
						description: res.message,
					});
				}

				setDataSource(data);
				refetchStudentQr();

				if (location.pathname === "/applicants/walk-in/add") {
					navigate(`/applicants/walk-in/${values.exam_schedule_id}`, {});
				} else if (
					location.pathname === `/applicants/walk-in/${params.id}/add`
				) {
					navigate(`/applicants/walk-in/${params.id}`, {});
				} else if (location.pathname === `/profiling-portal/add`) {
					setToggleModalProfile({
						open: true,
						data: null,
						username: username,
						qrFilePath: qrFilePath,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	useEffect(() => {
		const generateQrCode = async () => {
			try {
				const token = localStorage.getItem("token"); // Retrieve the token

				await axios
					.get(apiUrl("api/applicant_generate_qr_code"), {
						headers: {
							Authorization: `Bearer ${token}`,
						},
					})
					.then((res) => {
						if (res) {
							let data = res.data.data;
							setQrCode(data);
						}
					});
			} catch (error) {
				console.error("Error fetching data", error);
			}
		};

		if (params && !params.id) {
			generateQrCode();
		}
	}, [params]);

	return (
		<>
			<Form
				onFinish={onFinish}
				form={form}
				initialValues={{
					address_list: [{}],
				}}
				scrollToFirstError
				id={
					location.pathname.includes("/applicants/walk-in")
						? ""
						: "PageProfilingPortalAdd"
				}
			>
				{qrCode && (
					<div
						id="myqrcode"
						style={{
							visibility: "hidden",
							height: 0,
							overflow: "hidden",
						}}
					>
						<QRCode
							errorLevel="H"
							value={`${window.location.origin}/profiling-portal/qr_code?qr=${qrCode}`}
							bgColor="#ffffff"
							size={200}
							type="svg"
						/>
					</div>
				)}

				<Row gutter={[12, 12]}>
					{location.pathname.includes("/profiling-portal/add") && (
						<Col xs={24} sm={24} md={24} lg={12} xl={12} className="m-0">
							<Flex align="flex-start" vertical>
								<img
									width="400px"
									src="./../assets/images/fsuu_logo_v3.png"
									alt="fsuu-logo"
								/>

								<Col xs={24} sm={24} md={24} lg={12} xl={12}>
									<p
										style={{
											width: "320px",
											fontSize: "12px",
											textAlign: "justify",
											color: "#000",
											padding: "10px 20px",
											background: "rgba(90, 90, 90, 0.25)",
										}}
									>
										<strong>Privacy Statement: </strong>
										<em>
											The data provided by the users/applicants is solely used
											for basic profiling and will not be used for any malicious
											purposes.
										</em>
									</p>
								</Col>
							</Flex>
						</Col>
					)}

					<Col xs={24} sm={12} md={12} lg={12} xl={12}>
						<Card
							title="Profile Photo"
							className="card-main-primary card-profile-photo"
						>
							<ApplicantFormPhotoInfo
								action="add"
								toggleModalUploadProfilePicture={
									toggleModalUploadProfilePicture
								}
								setToggleModalUploadProfilePicture={
									setToggleModalUploadProfilePicture
								}
							/>
						</Card>
					</Col>

					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Card title="Personal Information" className="card-main-primary">
							<ApplicantFormBasicInfo
								form={form}
								dataCivilStatus={
									dataCivilStatus && dataCivilStatus.data
										? dataCivilStatus.data
										: []
								}
								dataNationalities={
									dataNationalities && dataNationalities.data
										? dataNationalities.data
										: []
								}
								dataReligion={
									dataReligion && dataReligion.data ? dataReligion.data : []
								}
							/>
							<ApplicantFormAddressInfo
								action="edit"
								form={form}
								profile_id={params.id}
							/>
						</Card>
					</Col>

					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Card
							title="Health Information"
							className="card-main-primary disability-info"
						>
							<ApplicantFormHealthInfo form={form} profile_id={params.id} />
						</Card>
					</Col>

					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Card
							title="Entrance Exam Information"
							className="card-main-primary"
						>
							<ApplicantFormExamInfo
								formDisabled={true}
								params={params}
								dataExamSchedule={dataExamSchedule}
								form={form}
								handleStudentLevelChange={handleStudentLevelChange}
								selectedStudentLevel={selectedStudentLevel}
							/>
						</Card>
					</Col>

					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Flex gap={10} justify="space-between">
							{location.pathname.includes("/profiling-portal/add") && (
								<Button
									variant="outlined"
									size="large"
									onClick={() => navigate("/profiling-portal")}
								>
									<FontAwesomeIcon icon={faArrowLeft} />
									&nbsp;Profiling Portal
								</Button>
							)}

							<Button
								type="primary"
								htmlType="submit"
								loading={isLoadingStudent}
								name="btn_submit"
								size={
									location.pathname.includes("/profiling-portal/add")
										? "large"
										: ""
								}
							>
								{location.pathname.includes("/profiling-portal/add")
									? "Submit for Verification"
									: "Submit"}
							</Button>
						</Flex>
					</Col>
				</Row>

				<ModalProfile toggleModalProfile={toggleModalProfile} />
			</Form>
		</>
	);
}
