import {
	Button,
	Col,
	Divider,
	Empty,
	Flex,
	Form,
	Modal,
	notification,
	Row,
	Tooltip,
	Upload,
} from "antd";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCamera,
	faRefresh,
	faUpload,
} from "@fortawesome/pro-regular-svg-icons";
import { useCallback, useEffect, useRef, useState } from "react";
import imageFileToBase64 from "../../../../providers/imageFileToBase64";
import dataURLtoBlob from "../../../../providers/dataURLtoBlob";
import { defaultDocument, userData } from "../../../../providers/companyInfo";
import Webcam from "react-webcam";
import validateRules from "../../../../providers/validateRules";
import FloatInput from "../../../../providers/FloatInput";
import { POST } from "../../../../providers/useAxiosQuery";
import notificationErrors from "../../../../providers/notificationErrors";

export default function ModalUploadOR(props) {
	const {
		toggleModalUploadOR,
		setToggleModalUploadOR,
		refetchStudentExam,
		setToggleModalFormORValidation,
		tableFilter,
	} = props;
	const [form] = Form.useForm();

	console.log("first toggle ModalUploadOR: ", toggleModalUploadOR);

	const { mutate: mutateApplicantOr, isLoading: isLoadingApplicantOr } = POST(
		`api/applicant_official_receipt`,
		"student_exams_list"
	);

	const onFinish = (values) => {
		let data = new FormData();
		data.append(
			"student_academic_id",
			toggleModalUploadOR.data.student_academic_id
		);
		data.append("profile_id", toggleModalUploadOR.data.profile_id);
		data.append("or_number", values.or_number);
		data.append("file_path", fileImage.file);

		console.log("data: ", data);

		mutateApplicantOr(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "Applicant Official Receipt",
						description: res.message,
					});
					setToggleModalUploadOR({
						open: false,
						data: null,
					});
					form.resetFields();
					setFileImage({
						is_camera: false,
						status: null,
						file: null,
						src: null,
						isCapture: false,
						fileName: null,
					});
					refetchStudentExam();
				} else {
					notification.error({
						message: "Applicant Official Receipt",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	const webcamRef = useRef(null);
	const [fileImage, setFileImage] = useState({
		is_camera: false,
		status: null,
		isCapture: false,
		file: null,
		src: null,
		fileName: null,
	});

	const propsUpload = {
		action: false,
		accept: ".jpg,.png",
		maxCount: 1,
		beforeUpload: (file) => {
			let error = false;
			const isJPG = file.type === "image/jpeg" || file.type === "image/png";

			if (!isJPG) {
				notification.error({
					message: "Upload Official Receipt",
					description: "You can only upload JPG/PNG file!",
				});
				error = Upload.LIST_IGNORE;
			}

			if (error === false) {
				imageFileToBase64(file, (imageUrl) =>
					setFileImage((ps) => ({
						...ps,
						src: imageUrl,
						file: file,
						fileName: file.name,
					}))
				);
			}

			return error;
		},
		showUploadList: false,
	};

	const handleOpenCamera = () => {
		navigator.mediaDevices
			.getUserMedia({
				audio: false,
				video: true,
			})
			.then(function(stream) {
				if (stream.getVideoTracks().length > 0) {
					setFileImage((ps) => ({
						...ps,
						is_camera: true,
						status: "available",
						message: "Camera detected...",
					}));
				} else {
					setFileImage((ps) => ({
						...ps,
						is_camera: true,
						status: "unavailable",
						message: "Camera not detected...",
					}));
				}
			})
			.catch(function(error) {
				setFileImage((ps) => ({
					...ps,
					is_camera: true,
					status: "error",
					message: error.message,
				}));
			});
	};

	const handleCapture = useCallback(() => {
		const imageSrc = webcamRef.current.getScreenshot();
		const blob = dataURLtoBlob(imageSrc);

		setFileImage((ps) => ({
			...ps,
			src: imageSrc,
			file: blob,
			isCapture: true,
			fileName: blob.size + "-camera.png",
		}));
	}, [webcamRef]);

	const handleRenderCamera = () => {
		if (fileImage.isCapture) {
			return (
				<img
					alt=""
					src={fileImage.src ? fileImage.src : defaultDocument}
					className="w-100"
				/>
			);
		} else {
			if (fileImage.status === "available") {
				return (
					<Webcam
						ref={webcamRef}
						style={{ maxWidth: "100%" }}
						disablePictureInPicture={true}
						screenshotFormat="image/jpeg"
						videoConstraints={{
							width: 1280,
							height: 720,
							facingMode: "user",
						}}
					/>
				);
			} else {
				return (
					<div
						style={{
							display: "flex",
							width: "100%",
							padding: "100px 0px",
							justifyContent: "center",
							alignItems: "center",
							background: "#000",
							color: "#fff",
							flexDirection: "column",
							gap: "12px 12px",
						}}
					>
						<div>
							{fileImage.message}
							<br />
							{fileImage.message === "Requested device not found"
								? "Please check your camera and try refresh the page."
								: "Please allow camera access and try refresh the page."}
						</div>
					</div>
				);
			}
		}
	};

	useEffect(() => {
		if (toggleModalUploadOR.open) {
			let data = { ...toggleModalUploadOR.data };

			// console.log("data: ", data);

			// Set file image if file_path is available
			if (data.file_path) {
				const fileUrl = `${process.env.REACT_APP_API_URL}/${data.file_path}`;
				setFileImage((ps) => ({
					...ps,
					src: fileUrl,
					file: data.file_path,
					fileName: data.attachment_name || "Uploaded File",
				}));
			}

			form.setFieldsValue(data);
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [toggleModalUploadOR]);

	return (
		<Modal
			title="FORM UPLOAD OFFICIAL RECEIPT"
			open={toggleModalUploadOR.open}
			onCancel={() => {
				setToggleModalUploadOR((ps) => ({
					...ps,
					open: false,
				}));
			}}
			forceRender
			footer={[
				<div
					key="footer-container"
					style={{
						display: "flex",
						justifyContent: "space-between",
					}}
					className="modal-official-receipt-footer"
				>
					<Tooltip
						title={
							!toggleModalUploadOR.data?.or_number
								? "Please submit the form first"
								: ""
						}
						key="tooltip"
					>
						<Button
							name="btn_submit"
							type={
								!toggleModalUploadOR.data?.or_number ||
								toggleModalUploadOR.data?.payment_status !== "Paid"
									? "primary"
									: "dashed"
							}
							onClick={() => {
								setToggleModalFormORValidation({
									open: true,
									data: {
										id: toggleModalUploadOR.data.id,
										payment_status: toggleModalUploadOR.data.payment_status,
										payment_notes: toggleModalUploadOR.data.payment_notes,
										or_number: toggleModalUploadOR.data.or_number,
										fullname: toggleModalUploadOR.data.fullname,
									},
									tableFilter: tableFilter,
								});
							}}
							disabled={
								!toggleModalUploadOR.data?.or_number ||
								toggleModalUploadOR.data?.payment_status === "Paid"
							}
						>
							VERIFY
						</Button>
					</Tooltip>

					<Flex gap={10}>
						<Button
							className="btn-main-primary outlined"
							key={1}
							onClick={() => {
								setToggleModalUploadOR((ps) => ({
									...ps,
									open: false,
								}));
								setFileImage({
									is_camera: false,
									status: null,
									file: null,
									src: null,
									isCapture: false,
									fileName: null,
								});
								form.resetFields();
							}}
						>
							CANCEL
						</Button>
						<Button
							className="btn-main-primary"
							htmlType="submit"
							type={
								toggleModalUploadOR.data?.payment_status !== "Paid"
									? "primary"
									: "dashed"
							}
							key={2}
							onClick={() => {
								if (userData().role !== "Super Admin" && !fileImage.file) {
									notification.error({
										message: "Upload Official Receipt",
										description: "Please upload your official receipt!",
									});
									return;
								} else {
									form.submit();
								}
							}}
							disabled={
								toggleModalUploadOR.data?.payment_status === "Paid" ||
								(userData().role !== "Super Admin" && !fileImage.file)
							}
							isLoading={isLoadingApplicantOr}
						>
							SUBMIT
						</Button>
					</Flex>
				</div>,
			]}
		>
			<Form form={form} onFinish={onFinish}>
				<Row gutter={[12, 12]}>
					<Col xs={24} sm={24} md={24} lg={24} xl={24}>
						<Flex gap={15}>
							<div style={{ fontSize: "16px", width: "100px" }}>
								Full Name
								<br />
								Exam
								<br />
								Date
								<br />
								Time
								<br />
								Amount
								<br />
								<br />
							</div>
							<div
								style={{
									fontSize: "16px",
									textDecoration: "underline",
									marginTop: "0px",
									fontWeight: "600",
									width: "100%",
								}}
							>
								{toggleModalUploadOR.data?.fullname}
								<br />
								{toggleModalUploadOR.data?.exam_category}
								<br />
								{toggleModalUploadOR.data?.exam_date &&
									dayjs(toggleModalUploadOR.data.exam_date).format(
										"MMMM DD, YYYY"
									)}
								<br />
								{toggleModalUploadOR.data?.exam_time}
								<br />
								Php {toggleModalUploadOR.data?.exam_fee}.00
								<br />
								<br />
								<Form.Item
									name="or_number"
									rules={[validateRules.quillValidator]}
								>
									<FloatInput
										label="OR Number"
										placeholder="OR Number"
										required
									/>
								</Form.Item>
							</div>
						</Flex>
					</Col>

					<Divider />

					<Col xs={24} sm={24} md={24} lg={24} xl={24} className="text-center">
						<Col xs={24} sm={24} md={24} lg={24} xl={24}>
							{!fileImage.is_camera ? (
								<>
									{fileImage.src ? (
										<img alt="" src={fileImage.src} className="w-100" />
									) : (
										<Empty
											image={Empty.PRESENTED_IMAGE_SIMPLE}
											description="No File Attached"
										/>
									)}
								</>
							) : (
								handleRenderCamera()
							)}
						</Col>
						<Col
							xs={24}
							sm={24}
							md={24}
							lg={24}
							xl={24}
							className="text-center"
						>
							{!fileImage.is_camera ? (
								<Upload {...propsUpload}>
									<Button icon={<FontAwesomeIcon icon={faUpload} />}>
										Upload Picture
									</Button>
								</Upload>
							) : (
								<Button
									icon={<FontAwesomeIcon icon={faUpload} />}
									onClick={() =>
										setFileImage({
											is_camera: false,
											status: null,
											file: null,
											src: null,
											isCapture: false,
										})
									}
								>
									Click to Upload
								</Button>
							)}

							{fileImage.is_camera ? (
								fileImage.status === "available" ? (
									fileImage.isCapture ? (
										<Button
											icon={<FontAwesomeIcon icon={faCamera} />}
											className="ml-10"
											onClick={() =>
												setFileImage((ps) => ({
													...ps,
													src: null,
													file: null,
													isCapture: false,
												}))
											}
										>
											Reset
										</Button>
									) : (
										<Button
											icon={<FontAwesomeIcon icon={faCamera} />}
											onClick={handleCapture}
											className="ml-10"
										>
											Capture
										</Button>
									)
								) : (
									<Button
										icon={<FontAwesomeIcon icon={faRefresh} />}
										onClick={() => window.location.reload()}
									>
										Refresh
									</Button>
								)
							) : (
								<Button
									icon={<FontAwesomeIcon icon={faCamera} />}
									onClick={handleOpenCamera}
									className="ml-10"
								>
									Click to Open Camera
								</Button>
							)}
						</Col>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
}
