import { Routes, Route } from "react-router-dom";
import {
	faHome,
	faUsers,
	faGraduationCap,
	faEnvelopes,
	faCogs,
} from "@fortawesome/pro-regular-svg-icons";

import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";

import PageRequestPermission from "../views/errors/PageRequestPermission";
import Page404 from "../views/errors/Page404";

import PageLogin from "../views/public/PageLogin/PageLogin";
import PageRegister from "../views/public/PageRegister/PageRegister";

import PageEditProfile from "../views/private/PageEditProfile/PageEditProfile";
import PageDashboard from "../views/private/PageDashboard/PageDashboard";

import PageApplicant from "../views/private/PageApplicants/PageApplicant";
import PageApplicantFormAdd from "../views/private/PageApplicants/PageApplicantFormAdd";
import PageApplicantFormEdit from "../views/private/PageApplicants/PageApplicantFormEdit";

import PageEntranceExam from "../views/private/PageEntranceExam/PageEntranceExam";
import PageApplicantList from "../views/private/PageApplicants/PageApplicantList";
import PageReport from "../views/private/PageReport/PageReport";
import PageSettings from "../views/private/PageReferences/PageSettings/PageSettings";
import PageEmailTemplate from "../views/private/PageEmailTemplate/PageEmailTemplate";
import PageTest from "../views/private/PageTest";
import PageProfileValidation from "../views/private/PageProfilingPortal/PageProfileValidation/PageProfileValidation";
import PageProfilingPortal from "../views/private/PageProfilingPortal/PageProfilingPortal";

export default function RouteList() {
	return (
		<Routes>
			<Route
				path="/"
				element={
					<PublicRoute title="LOGIN" pageId="PageLogin" component={PageLogin} />
				}
			/>

			<Route
				path="/registration"
				element={
					<PublicRoute
						title="REGISTRATION"
						pageId="PageRegistration"
						component={PageRegister}
					/>
				}
			/>

			<Route
				path="/edit-profile"
				element={
					<PrivateRoute
						moduleName="Edit Profile"
						title="User"
						subtitle="VIEW / EDIT"
						pageId="PageUserProfile"
						pageHeaderIcon={faUsers}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Edit Profile",
							},
						]}
						component={PageEditProfile}
					/>
				}
			/>

			<Route
				path="/dashboard"
				element={
					<PrivateRoute
						moduleCode="M-01"
						moduleName="Dashboard"
						title="Dashboard"
						subtitle="ADMIN"
						pageId="PageDashboard"
						pageHeaderIcon={faHome}
						breadcrumb={[
							{
								name: "Dashboard",
							},
						]}
						component={PageDashboard}
					/>
				}
			/>

			{/* START ENTRANCE EXAM */}
			<Route
				path="/entrance-exam/schedule"
				element={
					<PrivateRoute
						moduleCode="M-02"
						moduleName="Entrance Exam"
						title="Entrance Exam"
						subtitle="SCHEDULE"
						pageId="PageEntranceExam"
						pageHeaderIcon={faGraduationCap}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Entrance Exam",
							},
							{
								name: "Schedule",
							},
						]}
						component={PageEntranceExam}
					/>
				}
			/>

			{/* START WALK-IN APPLICANT */}
			<Route
				path="/applicants/walk-in"
				element={
					<PrivateRoute
						moduleCode="M-03"
						moduleName="Applicant Walk-in Schedule"
						title="Applicant"
						subtitle="WALK-IN"
						pageId="PageApplicant"
						pageHeaderIcon={faGraduationCap}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Applicant ",
								link: "/applicants/walk-in",
							},
							{
								name: "Walk-In",
							},
						]}
						component={PageApplicant}
					/>
				}
			/>

			<Route
				path="/applicants/walk-in/:id"
				element={
					<PrivateRoute
						moduleCode="M-04"
						moduleName="Applicant Walk-in View List"
						title="Applicant"
						subtitle="WALK-IN"
						pageId="PageApplicant"
						pageHeaderIcon={faGraduationCap}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Applicant ",
								link: "/applicants/walk-in",
							},
							{
								name: "Walk-In",
								link: "/applicants/walk-in",
							},
							{
								name: "Exam Schedule",
								className: "brExamSchedule",
							},
						]}
						component={PageApplicantList}
					/>
				}
			/>

			<Route
				path="/applicants/walk-in/:id/add"
				element={
					<PrivateRoute
						moduleCode="M-05"
						moduleName="Applicant Walk-In Add"
						title="Applicant"
						subtitle="WALK-IN ADD"
						pageId="PageApplicantFormAdd"
						pageHeaderIcon={faGraduationCap}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Applicant",
								link: "/applicants/walk-in",
							},
							{
								name: "Walk-In",
								link: "/applicants/walk-in",
							},
							{
								name: "Exam Schedule",
								className: "brExamSchedule",
							},
						]}
						component={PageApplicantFormAdd}
					/>
				}
			/>

			<Route
				path="/applicants/walk-in/add"
				element={
					<PrivateRoute
						moduleCode="M-05"
						moduleName="Applicant Walk-In Add"
						title="Applicant"
						subtitle="WALK-IN ADD"
						pageId="PageApplicantFormAdd"
						pageHeaderIcon={faGraduationCap}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Applicant",
								link: "/applicants/walk-in",
							},
							{
								name: "Walk-In",
								link: "/applicants/walk-in",
							},
							{
								name: "Add",
							},
						]}
						component={PageApplicantFormAdd}
					/>
				}
			/>

			<Route
				path="/applicants/walk-in/edit/:id"
				element={
					<PrivateRoute
						moduleCode="M-06"
						moduleName="Applicant Walk-In Edit"
						title="Applicant"
						subtitle="WALK-IN EDIT"
						pageId="PageApplicantFormEdit"
						pageHeaderIcon={faGraduationCap}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Applicant",
								link: "/applicants/walk-in",
							},
							{
								name: "Walk-In",
								link: "/applicants/walk-in",
							},
							{
								name: "Edit",
							},
						]}
						component={PageApplicantFormEdit}
					/>
				}
			/>
			{/* END WALK-IN APPLICANT */}

			{/* START ONLINE APPLICANT */}
			<Route
				path="/applicants/online"
				element={
					<PrivateRoute
						moduleCode="M-07"
						moduleName="Applicant Online Schedule"
						title="Applicant"
						subtitle="ONLINE"
						pageId="PageApplicant"
						pageHeaderIcon={faGraduationCap}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Applicant ",
								link: "/applicants/online",
							},
							{
								name: "Online",
							},
						]}
						component={PageApplicant}
					/>
				}
			/>

			<Route
				path="/applicants/online/:id"
				element={
					<PrivateRoute
						moduleCode="M-08"
						moduleName="Applicant Online View List"
						title="Applicant"
						subtitle="ONLINE"
						pageId="PageApplicant"
						pageHeaderIcon={faGraduationCap}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Applicant ",
								link: "/applicants/online",
							},
							{
								name: "Online",
								link: "/applicants/online",
							},
							{
								name: "Exam Schedule",
								className: "brExamSchedule",
							},
						]}
						component={PageApplicantList}
					/>
				}
			/>

			<Route
				path="/applicants/online/add"
				element={
					<PrivateRoute
						moduleCode="M-09"
						moduleName="Applicant Online Add"
						title="Applicant"
						subtitle="ONLINE ADD"
						pageId="PageApplicantFormAdd"
						pageHeaderIcon={faGraduationCap}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Applicant",
								link: "/applicants/online",
							},
							{
								name: "Online",
								link: "/applicants/online",
							},
							{
								name: "Add",
							},
						]}
						component={PageApplicantFormAdd}
					/>
				}
			/>

			<Route
				path="/applicants/online/edit/:id"
				element={
					<PrivateRoute
						moduleCode="M-10"
						moduleName="Applicant Online Edit"
						title="Applicant"
						subtitle="ONLINE EDIT"
						pageId="PageApplicantFormEdit"
						pageHeaderIcon={faGraduationCap}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Applicant",
								link: "/applicants/online",
							},
							{
								name: "Online",
								link: "/applicants/online",
							},
							{
								name: "Edit",
							},
						]}
						component={PageApplicantFormEdit}
					/>
				}
			/>
			{/* END ONLINE APPLICANT */}

			{/* REPORTS */}
			<Route
				path="/reports"
				element={
					<PrivateRoute
						moduleCode="M-13"
						moduleName="Reports"
						title="Reports"
						subtitle="REPORTS"
						pageId="PageReport"
						pageHeaderIcon={faGraduationCap}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Reports",
							},
						]}
						component={PageReport}
					/>
				}
			/>

			{/* SETTINGS */}
			<Route
				path="/settings"
				element={
					<PrivateRoute
						moduleCode="M-14"
						moduleName="Admin Settings"
						title="List"
						subtitle="SETTINGS"
						pageId="PageAdminSettings"
						pageHeaderIcon={faCogs}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Settings",
							},
						]}
						component={PageSettings}
					/>
				}
			/>

			{/* EMAIL TEMPLATE */}
			<Route
				path="/email-template"
				element={
					<PrivateRoute
						moduleCode="M-15"
						moduleName="Email Template"
						title="Template"
						subtitle="EMAIL"
						pageId="PageEmailTemplate"
						pageClassName="email-template"
						pageHeaderIcon={faEnvelopes}
						breadcrumb={[
							{
								name: "Email Template",
							},
						]}
						component={PageEmailTemplate}
					/>
				}
			/>

			{/* START PROFILING PORTAL */}
			<Route
				path="/profiling-portal"
				element={
					<PageProfilingPortal
						moduleCode="M-16"
						moduleName="Profiling Portal"
						title="Portal"
						subtitle="PROFILING"
						pageId="PageProfilingPortal"
					/>
				}
			/>

			<Route
				path="/profiling-portal/add"
				element={
					<PageApplicantFormAdd
						moduleCode="M-16"
						moduleName="Profiling Portal"
						title="Portal"
						subtitle="PROFILING"
						pageId="PageProfilingPortal"
					/>
				}
			/>

			<Route
				path="/profiling-portal/edit/:id"
				element={
					<PageApplicantFormEdit
						moduleCode="M-16"
						moduleName="Profiling Portal"
						title="Portal"
						subtitle="PROFILING"
						pageId="PageProfilingPortal"
					/>
				}
			/>
			{/* END PROFILING PORTAL */}

			{/* START PROFILING VALIDATION */}
			<Route
				path="/profile-validation"
				element={
					<PrivateRoute
						moduleCode="M-17"
						moduleName="Profile Validation"
						title="Profile"
						subtitle="VALIDATION"
						pageId="PageProfileValidation"
						pageHeaderIcon={faHome}
						breadcrumb={[
							{
								name: "Dashboard",
								link: "/dashboard",
							},
							{
								name: "Profile Validation",
							},
						]}
						component={PageProfileValidation}
					/>
				}
			/>
			{/* END PROFILING VALIDATION */}

			{/* VOTING */}
			<Route
				path="/test"
				element={
					<PageTest
						moduleCode="M-16"
						moduleName="Email Template"
						title="Template"
						subtitle="EMAIL"
						pageId="PageEmailTemplate"
						pageClassName="email-template"
						pageHeaderIcon={faEnvelopes}
						breadcrumb={[
							{
								name: "Email Template",
							},
						]}
					/>
				}
			/>

			<Route path="/request-permission" element={<PageRequestPermission />} />
			<Route path="*" element={<Page404 />} />
		</Routes>
	);
}
