import {
	faAddressCard,
	faCogs,
	faHome,
	faGraduationCap,
	faCalendarDay,
	faChartSimple,
	faEnvelopes,
	faBadgeCheck,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu } from "antd";

export const adminHeaderMenuLeft = (
	<>
		{/* <div className="ant-menu-left-icon">
            <Link to="/subscribers/current">
                <span className="anticon">
                    <FontAwesomeIcon icon={faUsers} />
                </span>
                <Typography.Text>Subscribers</Typography.Text>
            </Link>
        </div> */}
	</>
);

export const adminHeaderDropDownMenuLeft = () => {
	const items = [
		// {
		//     key: "/subscribers/current",
		//     icon: <FontAwesomeIcon icon={faUsers} />,
		//     label: <Link to="/subscribers/current">Subscribers</Link>,
		// },
	];

	return <Menu items={items} />;
};

export const adminSideMenu = [
	{
		title: "Dashboard",
		path: "/dashboard",
		icon: <FontAwesomeIcon icon={faHome} />,
		moduleCode: "M-01",
	},
	{
		title: "Entrance Exam",
		path: "/entrance-exam/schedule",
		icon: <FontAwesomeIcon icon={faCalendarDay} />,
		moduleCode: "M-02",
	},
	{
		title: "Applicants",
		path: "/applicants",
		icon: <FontAwesomeIcon icon={faGraduationCap} />,
		children: [
			{
				title: "Walk-In",
				path: "/applicants/walk-in",
				moduleCode: "M-03",
			},
			{
				title: "Online",
				path: "/applicants/online",
				moduleCode: "M-07",
			},
		],
	},
	{
		title: "Reports",
		path: "/reports",
		icon: <FontAwesomeIcon icon={faChartSimple} />,
		moduleCode: "M-13",
	},
	{
		title: "Settings",
		path: "/settings",
		icon: <FontAwesomeIcon icon={faCogs} />,
		moduleCode: "M-14",
	},
	{
		title: "Email Template",
		path: "/email-template",
		icon: <FontAwesomeIcon icon={faEnvelopes} />,
		moduleCode: "M-15",
	},
	{
		title: "Profiling",
		path: "/profiling-portal",
		icon: <FontAwesomeIcon icon={faAddressCard} />,
		moduleCode: "M-16",
	},
	{
		title: "Profile Validation",
		path: "/profile-validation",
		icon: <FontAwesomeIcon icon={faBadgeCheck} />,
		moduleCode: "M-17",
	},
];
