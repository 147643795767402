import { useEffect, useState } from "react";
import { Col, Row, Form, Radio, Checkbox, notification, Button } from "antd";

import { GET, POST } from "../../../../providers/useAxiosQuery";
import FloatInput from "../../../../providers/FloatInput";
import notificationErrors from "../../../../providers/notificationErrors";
import validateRules from "../../../../providers/validateRules";
import ModalAuthPassword from "../components/ModalAuthPassword";

export default function ApplicantFormAdditionalInfo({ profile_id }) {
	const [form] = Form.useForm();
	const [formData, setFormData] = useState(null);

	const [toggleModalAuthPassword, setToggleModalAuthPassword] = useState({
		open: false,
		data: null,
	});

	const handleHeardAboutFSUU = (checkedValues) => {
		// handleDebounce(checkedValues, "heard_about_fsuu");
	};

	const handleFactorsDecision = (checkedValues) => {
		// handleDebounce(checkedValues, "decision_factors");
	};

	const { refetch: refetchAdditionalInfo } = GET(
		`api/student_exams/${profile_id}`,
		["student_exams_info", "check_user_permission"],
		(res) => {
			if (res.data) {
				let data = res.data;

				let heard_about_fsuu = null;
				let decision_influence = null;
				let decision_factors = null;
				let other_factors = null;

				if (data.student_academics && data.student_academics.length > 0) {
					let additionalInfo = null;
					if (additionalInfo) {
						heard_about_fsuu = additionalInfo.heard_about_fsuu;
						decision_influence = additionalInfo.decision_influence;
						decision_factors = additionalInfo.decision_factors;
						other_factors = additionalInfo.other_factors;
					}
				}

				form.setFieldValue({
					heard_about_fsuu,
					decision_influence,
					decision_factors,
					other_factors,
				});
			}
		},
		false
	);

	useEffect(() => {
		refetchAdditionalInfo();
	}, [profile_id]);

	const {
		mutate: mutateAdditionalInfo,
		isLoading: isLoadingUpdateChanges,
	} = POST(`api/update_additional_information`, "student_exams_list");

	const onFinish = (auth_password) => {
		let values = formData;

		let data = {
			...values,
			profile_id: parseInt(profile_id, 10),

			// Auth Password Address for Changes
			auth_password: auth_password,
		};

		mutateAdditionalInfo(data, {
			onSuccess: (res) => {
				notification.success({
					message: "Additional Info Updated",
					description: res.message,
				});
				setToggleModalAuthPassword({
					open: false,
					data: null,
				});
			},
			onError: (err) => {
				notificationErrors(err, form);
			},
		});
	};

	return (
		<Form form={form} onFinish={onFinish} className="additional-info">
			<Row gutter={[18, 0]}>
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<p className="mt-0">
						1. Have you ever heard about FATHER SATURNINO URIOS UNIVERSITY
						(FSUU) in the session conducted by the FSUU personnel in your
						school?
					</p>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Form.Item name="heard_about_fsuu">
						<Radio.Group onChange={handleHeardAboutFSUU}>
							<Radio value={`Yes`}>Yes</Radio>
							<Radio value="No">No</Radio>
						</Radio.Group>
					</Form.Item>

					{/* Show if  heardAboutFSUU === Yes */}
					<Form.Item shouldUpdate noStyle>
						{() => {
							let show = false;
							let heard_about_fsuu = form.getFieldValue("heard_about_fsuu");

							if (heard_about_fsuu === "Yes") {
								return (
									<>
										<p className="mt-0">
											If yes, Did it influence your decision to enroll in FATHER
											SATURNINO URIOS UNIVERSITY?
										</p>
										<Col
											xs={24}
											sm={12}
											md={12}
											lg={6}
											xl={6}
											className="influenceRadio"
										>
											<Form.Item name="decision_influence">
												<Radio.Group>
													<Radio value="Yes">Yes</Radio>
													<Radio value="No">No</Radio>
												</Radio.Group>
											</Form.Item>
										</Col>
									</>
								);
							}
						}}
					</Form.Item>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<p className="mt-0">
						2. What were the factors that made you decide to enroll at FATHER
						SATURNINO URIOS UNIVERSITY?
					</p>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Form.Item name="decision_factors">
						<Checkbox.Group
							onChange={handleFactorsDecision}
							className="checkbox-wrapper"
						>
							<Checkbox value="The university offers quality education">
								A.) The university offers quality education
							</Checkbox>
							<Checkbox value="Parent's choice">B.) Parent's choice</Checkbox>
							<Checkbox value="It's near the residence">
								C.) It's near the residence
							</Checkbox>
							<Checkbox
								// value={`${otherFactor}`}
								value={`Other`}
								className="checkbox-wrapper-item"
							>
								D.) Other, please specify
							</Checkbox>
						</Checkbox.Group>
					</Form.Item>

					<Form.Item shouldUpdate noStyle>
						{() => {
							let show = false;
							let decision_factors = form.getFieldValue("decision_factors");
							if (decision_factors) {
								let decision_factors_find = decision_factors.find(
									(f) => f === "Other"
								);
								if (decision_factors_find) {
									return (
										<Form.Item
											name="other_factors"
											rules={[validateRules.quillValidator, validateRules.trim]}
										>
											<FloatInput
												id="otherFactor"
												label="Specify other factor"
												placeholder="Specify other factor"
											/>
										</Form.Item>
									);
								}
							}
						}}
					</Form.Item>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Button
						type="primary"
						className="btn-main-primary mb-20 w-xs-100"
						onClick={() => {
							setFormData(form.getFieldsValue());
							setToggleModalAuthPassword({
								open: true,
							});
						}}
					>
						Change
					</Button>
				</Col>
			</Row>
			{/* Modal */}
			<ModalAuthPassword
				toggleModalAuthPassword={toggleModalAuthPassword}
				setToggleModalAuthPassword={setToggleModalAuthPassword}
				onFinish2={onFinish}
				profile_id={profile_id}
				isLoadingUpdateChanges={isLoadingUpdateChanges}
			/>
		</Form>
	);
}
