import { useEffect } from "react";
import { Modal, Button, Form, notification } from "antd";

import { POST } from "../../../../providers/useAxiosQuery";
import validateRules from "../../../../providers/validateRules";
import FloatInput from "../../../../providers/FloatInput";

export default function ModalForm(props) {
	const { toggleModalForm, setToggleModalForm } = props;

	const [form] = Form.useForm();

	const { mutate: mutateExamCategory, isLoading: isLoadingExamCategory } = POST(
		`api/exam_category`,
		"exam_category_list"
	);

	const onFinish = (values) => {
		// console.log("values:", values);

		let data = {
			...values,
			id:
				toggleModalForm.data && toggleModalForm.data.id
					? toggleModalForm.data.id
					: "",
		};

		mutateExamCategory(data, {
			onSuccess: (res) => {
				console.log("res", res);
				if (res.success) {
					setToggleModalForm({
						open: false,
						data: null,
					});
					form.resetFields();
					notification.success({
						message: "Exam Category",
						description: res.message,
					});
				} else {
					notification.error({
						message: "Exam Category",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notification.error({
					message: "Exam Category",
					description: "The exam category has already been taken.",
				});
			},
		});
	};

	useEffect(() => {
		if (toggleModalForm.open) {
			form.setFieldsValue({
				...toggleModalForm.data,
			});
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [toggleModalForm]);

	return (
		<Modal
			title="FORM EXAM CATEGORY"
			open={toggleModalForm.open}
			onCancel={() => {
				setToggleModalForm({
					open: false,
					data: null,
				});
				form.resetFields();
			}}
			forceRender
			footer={[
				<Button
					className="btn-main-primary outlined"
					key={1}
					onClick={() => {
						setToggleModalForm({
							open: false,
							data: null,
						});
						form.resetFields();
					}}
				>
					CANCEL
				</Button>,
				<Button
					className="btn-main-primary"
					type="primary"
					key={2}
					onClick={() => form.submit()}
					loading={isLoadingExamCategory}
				>
					SUBMIT
				</Button>,
			]}
		>
			<Form form={form} onFinish={onFinish}>
				<Form.Item name="category" rules={[validateRules.required]}>
					<FloatInput
						label="Exam Category"
						placeholder="Exam Category"
						required={true}
					/>
				</Form.Item>

				<Form.Item
					name="exam_fee"
					rules={[validateRules.quillValidator, validateRules.number]}
				>
					<FloatInput label="Exam Fee" placeholder="Exam Fee" required={true} />
				</Form.Item>
			</Form>
		</Modal>
	);
}
