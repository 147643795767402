import { useEffect } from "react";
import { Modal, Button, Form, notification } from "antd";

import { POST } from "../../../../providers/useAxiosQuery";
import validateRules from "../../../../providers/validateRules";
import FloatInput from "../../../../providers/FloatInput";
import FloatSelect from "../../../../providers/FloatSelect";
import FloatInputPassword from "../../../../providers/FloatInputPassword";

export default function ModalForm(props) {
	const { toggleModalForm, setToggleModalForm, dataUserRole } = props;

	const [form] = Form.useForm();

	const { mutate: mutateExamCategory, loading: isLoading } = POST(
		`api/add_user`,
		"add_user_list"
	);

	const onFinish = (values) => {
		let data = {
			...values,
			id:
				toggleModalForm.data && toggleModalForm.data.id
					? toggleModalForm.data.id
					: "",
		};

		mutateExamCategory(data, {
			onSuccess: (res) => {
				if (res.success) {
					setToggleModalForm({
						open: false,
						data: null,
					});
					form.resetFields();
					setToggleModalForm = {
						open: false,
						data: null,
					};
					notification.success({
						message: "User",
						description: res.message,
					});
				} else {
					notification.error({
						message: "User",
						description: res.message,
					});
				}
			},
			onError: (err) => {
				notification.error({
					message: "User",
					description: "The user has already been taken.",
				});
			},
		});
	};

	useEffect(() => {
		if (toggleModalForm.open) {
			form.setFieldsValue({
				...toggleModalForm.data,
			});
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [toggleModalForm]);

	return (
		<Modal
			title="FORM USER"
			open={toggleModalForm.open}
			onCancel={() => {
				setToggleModalForm({
					open: false,
					data: null,
				});
				form.resetFields();
			}}
			forceRender
			footer={[
				<Button
					className="btn-main-primary outlined"
					key={1}
					onClick={() => {
						setToggleModalForm({
							open: false,
							data: null,
						});
						form.resetFields();
					}}
				>
					CANCEL
				</Button>,
				<Button
					className="btn-main-primary"
					type="primary"
					name="btn_submit"
					key={2}
					onClick={() => form.submit()}
					loading={isLoading}
				>
					SUBMIT
				</Button>,
			]}
		>
			<Form form={form} onFinish={onFinish}>
				<Form.Item name="user_role_id" rules={[validateRules.quillValidator]}>
					<FloatSelect
						label="User Role"
						placeholder="User Role"
						required={true}
						allowClear={true}
						options={
							dataUserRole
								? dataUserRole.map((item) => ({
										value: item.id,
										label: item.role,
								  }))
								: []
						}
					/>
				</Form.Item>

				<Form.Item name="username" rules={[validateRules.quillValidator]}>
					<FloatInput
						label="User Name"
						placeholder="User Name"
						required={true}
					/>
				</Form.Item>

				<Form.Item
					name="email"
					rules={[validateRules.quillValidator, validateRules.email]}
				>
					<FloatInput label="Email" placeholder="Email" required={true} />
				</Form.Item>

				<Form.Item
					name="password"
					rules={[validateRules.quillValidator, validateRules.password]}
				>
					<FloatInputPassword
						label="Password"
						placeholder="Password"
						required={true}
					/>
				</Form.Item>
			</Form>
		</Modal>
	);
}
