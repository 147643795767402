import {
	Button,
	Col,
	Flex,
	notification,
	Popconfirm,
	Row,
	Table,
	Tag,
	Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";

import { GET, POST } from "../../../../providers/useAxiosQuery";
import {
	TableGlobalSearch,
	TablePageSize,
	TablePagination,
	TableShowingEntriesV2,
	useTableScrollOnTop,
} from "../../../../providers/CustomTableFilter";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard, faTrash } from "@fortawesome/pro-regular-svg-icons";

import ModalFormProfile from "../components/ModalFormProfile";
import notificationErrors from "../../../../providers/notificationErrors";
import ModalFormValidation from "../components/ModalFormValidation";

export default function PageProfileValidation() {
	const location = useLocation();
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);

	const [toggleModalFormProfile, setToggleModalFormProfile] = useState({
		open: false,
		data: null,
	});
	const [toggleModalFormValidation, setToggleModalFormValidation] = useState({
		open: false,
		data: null,
	});

	const [tableFilter, setTableFilter] = useState({
		page: 1,
		page_size: 50,
		search: "",
		status: "Active",
		from: "Profile Validation List View TEST",
	});

	useEffect(() => {
		setTableFilter({
			page: 1,
			page_size: 50,
			search: "",
			isTrash: 0,
		});
	}, [location]);

	const { data: dataStudents, refetch: refetchStudentExam } = GET(
		`api/student_exams?${new URLSearchParams(tableFilter)}`,
		"student_exams_info"
	);

	const {
		mutate: mutateArchivedApplicant,
		isLoading: isLoadingArchivedApplicant,
	} = POST(`api/applicant_archived`, "student_exams_list");

	const handleSelectedArchived = (id) => {
		let data = {
			isTrash: tableFilter.isTrash,
			ids: selectedRowKeys.length > 0 ? selectedRowKeys : [id],
		};

		mutateArchivedApplicant(data, {
			onSuccess: (res) => {
				if (res.success) {
					notification.success({
						message: "Applicant",
						description: res.message,
					});
				} else {
					notification.error({
						message: "Applicant",
						description: res.message,
					});
				}
				refetchStudentExam();
			},
			onError: (err) => {
				notificationErrors(err);
			},
		});
	};

	useEffect(() => {
		refetchStudentExam();

		return () => {};
	}, [tableFilter]);

	useTableScrollOnTop("tblProfileValidation", location);

	return (
		<>
			<Row gutter={[12, 12]}>
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<div className="tbl-top-filter">
						<Flex gap={10}>
							<Button
								type={tableFilter.isTrash === 0 ? "primary" : ""}
								className={`min-w-150 ${
									tableFilter.isTrash === 0 ? "active" : ""
								}`}
								onClick={() => {
									setTableFilter((ps) => ({
										...ps,
										isTrash: 0,
									}));
									setSelectedRowKeys([]);
								}}
							>
								Active
							</Button>

							<Button
								type={tableFilter.isTrash === 1 ? "primary" : ""}
								className={`min-w-150 ${
									tableFilter.isTrash === 1 ? "active" : ""
								}`}
								onClick={() => {
									setTableFilter((ps) => ({
										...ps,
										isTrash: 1,
									}));
									setSelectedRowKeys([]);
								}}
							>
								Archived
							</Button>

							{selectedRowKeys.length > 0 && (
								<Popconfirm
									title={
										<>
											Are you sure you want to
											<br />
											{tableFilter.isTrash === 0 ? "archive" : "restore"} the
											selected{" "}
											{selectedRowKeys.length > 1 ? "applicants" : "applicant"}?
										</>
									}
									okText="Yes"
									cancelText="No"
									onConfirm={() => {
										handleSelectedArchived();
									}}
									disabled={isLoadingArchivedApplicant}
								>
									<Button
										type="primary"
										name="btn_active_archive"
										loading={isLoadingArchivedApplicant}
										danger={tableFilter.isTrash === 0}
										className={tableFilter.isTrash === 1 ? "btn-success" : ""}
									>
										{tableFilter.isTrash === 0 ? "ARCHIVE" : "RESTORE"} SELECTED
									</Button>
								</Popconfirm>
							)}
						</Flex>
						<Flex gap={10}>
							<TableShowingEntriesV2 />
							<TablePageSize
								tableFilter={tableFilter}
								setTableFilter={setTableFilter}
							/>
						</Flex>
					</div>
				</Col>

				<Col xs={24}>
					<Row gutter={[12, 12]}>
						<Col xs={24}>
							<Table
								id="tblProfileValidation"
								className="ant-table-default ant-table-striped"
								dataSource={dataStudents && dataStudents.data.data}
								rowKey={(record) => record.id}
								pagination={false}
								bordered={false}
								scroll={{ x: "max-content" }}
								sticky={{
									offsetHeader: 0,
								}}
								rowSelection={
									tableFilter.isTrash === 1
										? {
												selectedRowKeys,
												onChange: setSelectedRowKeys,
										  }
										: null
								}
							>
								{tableFilter.isTrash === 0 && (
									<Table.Column
										title="Action"
										key="action"
										dataIndex="action"
										align="center"
										width={100}
										render={(text, record) => (
											<Flex>
												<Tooltip title="Applicant Profile">
													<Button
														type="link"
														name="btn_edit"
														className="primary-color"
														onClick={() =>
															setToggleModalFormProfile({
																open: true,
																data: record,
															})
														}
													>
														<FontAwesomeIcon icon={faAddressCard} />
													</Button>
												</Tooltip>

												<Tooltip title="Archive">
													<Popconfirm
														title={
															<>
																Are you sure you want to
																<br />
																archive the applicant?
															</>
														}
														okText="Yes"
														cancelText="No"
														onConfirm={() => handleSelectedArchived(record.id)}
														disabled={isLoadingArchivedApplicant}
													>
														<Button
															type="link"
															className="primary-color text-danger"
															name="btn_edit"
														>
															<FontAwesomeIcon icon={faTrash} />
														</Button>
													</Popconfirm>
												</Tooltip>
											</Flex>
										)}
									/>
								)}

								<Table.Column
									title="Student Name"
									key="fullname"
									dataIndex="fullname"
									sorter={(a, b) => a.fullname.localeCompare(b.fullname)}
									defaultSortOrder="ascend"
									render={(text, record) => <>{record.fullname}</>}
									width={150}
								/>

								<Table.Column
									title="Profile Status"
									key="verify_status"
									dataIndex="verify_status"
									align="center"
									sorter={(a, b) => a.verify_status - b.verify_status}
									width={150}
									render={(text, record) => (
										<Button
											style={{
												background:
													record.verify_status === "Validated"
														? "#4EB459"
														: "#114eaf",
												color: "#ffff",
											}}
											onClick={() =>
												setToggleModalFormValidation({
													open: true,
													data: record,
												})
											}
										>
											{record.verify_status}
										</Button>
									)}
								/>

								<Table.Column
									title="Notes"
									key="verify_notes"
									dataIndex="verify_notes"
									sorter={(a, b) => a.verify_notes - b.verify_notes}
									width={250}
								/>

								<Table.Column
									title="Exam Date"
									key="exam_date"
									dataIndex="exam_date"
									sorter={(a, b) => a.exam_date.localeCompare(b.exam_date)}
									defaultSortOrder="ascend"
									render={(text, record) => (
										<>{dayjs(record.exam_date).format("MMMM DD, YYYY")}</>
									)}
								/>

								<Table.Column
									title="Exam Category"
									key="exam_category"
									dataIndex="exam_category"
									sorter={(a, b) =>
										a.exam_category.localeCompare(b.exam_category)
									}
									defaultSortOrder="ascend"
									render={(text, record) => (
										<>
											{record.exam_category}{" "}
											<Tag color="blue" style={{ fontSize: "12px" }}>
												₱ {record.exam_fee}.00
											</Tag>
										</>
									)}
								/>

								<Table.Column
									title="Email"
									key="email"
									dataIndex="email"
									sorter={(a, b) => a.email.localeCompare(b.email)}
									defaultSortOrder="ascend"
								/>
							</Table>
						</Col>

						<Col xs={24}>
							<div className="tbl-bottom-filter">
								<TableShowingEntriesV2 />
								<TablePagination
									tableFilter={tableFilter}
									setTableFilter={setTableFilter}
									setPaginationTotal={dataStudents?.data?.total}
									showLessItems={true}
									showSizeChanger={false}
									tblIdWrapper="tbl_wrapper"
								/>
							</div>
						</Col>
					</Row>
				</Col>

				<ModalFormProfile
					toggleModalFormProfile={toggleModalFormProfile}
					setToggleModalFormProfile={setToggleModalFormProfile}
					refetchStudentExam={refetchStudentExam}
				/>

				<ModalFormValidation
					toggleModalFormValidation={toggleModalFormValidation}
					setToggleModalFormValidation={setToggleModalFormValidation}
					setToggleModalFormProfile={setToggleModalFormProfile}
					refetchStudentExam={refetchStudentExam}
				/>
			</Row>
		</>
	);
}
