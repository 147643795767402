import { useEffect, useState } from "react";
import { Modal, Button, Form } from "antd";

import FloatSelect from "../../../../providers/FloatSelect";
import validateRules from "../../../../providers/validateRules";
import FloatTextArea from "../../../../providers/FloatTextArea";
import ModalAuthPassword from "../../PageApplicants/components/ModalAuthPassword";

export default function ModalFormValidation(props) {
	const {
		toggleModalFormValidation,
		setToggleModalFormValidation,
		setToggleModalFormProfile,
		refetchStudentExam,
	} = props;

	const [form] = Form.useForm();
	const [toggleModalAuthPassword, setToggleModalAuthPassword] = useState({
		open: false,
		data: null,
	});

	useEffect(() => {
		if (toggleModalFormValidation.open) {
			form.setFieldsValue({
				...toggleModalFormValidation.data,
			});
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [toggleModalFormValidation]);

	return (
		<>
			<Modal
				title={`FORM VALIDATE APPLICANT: ${toggleModalFormValidation.data &&
					toggleModalFormValidation.data.fullname}`}
				open={toggleModalFormValidation.open}
				onCancel={() => {
					setToggleModalFormValidation({
						open: false,
						data: null,
					});
					form.resetFields();
				}}
				forceRender
				footer={[
					<Button
						className="btn-main-primary outlined"
						key={1}
						onClick={() => {
							setToggleModalFormValidation({
								open: false,
								data: null,
							});
							form.resetFields();
						}}
					>
						CANCEL
					</Button>,
					<Button
						className="btn-main-primary"
						type="primary"
						key={2}
						onClick={() => {
							setToggleModalFormValidation({
								open: false,
								data: null,
							});
							form.validateFields().then((formValues) => {
								let values = {
									...formValues,
									id: toggleModalFormValidation.data.id,
								};
								setToggleModalAuthPassword({
									open: true,
									data: values,
									from: "Profile Validation List View",
									exam_schedule_id:
										toggleModalFormValidation.data.exam_schedule_id,
								});
							});
						}}
					>
						SUBMIT
					</Button>,
				]}
			>
				<Form form={form}>
					<Form.Item name="verify_status" rules={[validateRules.required]}>
						<FloatSelect
							placeholder="Verify Status"
							label="Verify Status"
							required={true}
							value={
								toggleModalFormValidation.data &&
								toggleModalFormValidation.data.verify_status
							}
							options={[
								{ value: "For Validation", label: "For Validation" },
								{ value: "Validated", label: "Validated" },
							]}
						/>
					</Form.Item>

					<Form.Item name="verify_notes">
						<FloatTextArea placeholder="Notes" label="Notes" />
					</Form.Item>
				</Form>
			</Modal>

			<ModalAuthPassword
				toggleModalAuthPassword={toggleModalAuthPassword}
				setToggleModalAuthPassword={setToggleModalAuthPassword}
				setToggleModalFormProfile={setToggleModalFormProfile}
				refetchStudentExam={refetchStudentExam}
			/>
		</>
	);
}
