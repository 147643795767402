import { Modal, Button, Form, notification } from "antd";

import { POST } from "../../../../providers/useAxiosQuery";
import validateRules from "../../../../providers/validateRules";
import FloatSelect from "../../../../providers/FloatSelect";
import FloatTextArea from "../../../../providers/FloatTextArea";
import { useEffect, useState } from "react";
import ModalAuthPassword from "./ModalAuthPassword";

export default function ModalFormORValidation(props) {
	const {
		toggleModalFormORValidation,
		setToggleModalFormORValidation,
		refetchStudentExam,
	} = props;

	console.log(
		"second toggle ModalFormORValidation: ",
		toggleModalFormORValidation
	);

	const [form] = Form.useForm();

	const [toggleModalAuthPassword, setToggleModalAuthPassword] = useState({
		open: false,
		data: null,
	});

	const { mutate: mutateEmail, isLoading: isLoadingUserEmail } = POST(
		`api/users_update_email`,
		"users_info"
	);

	useEffect(() => {
		if (toggleModalFormORValidation.open) {
			form.setFieldsValue({
				...toggleModalFormORValidation.data,
			});
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [toggleModalFormORValidation]);

	return (
		<>
			<Modal
				title={`VALIDATE OFFICIAL RECEIPT: ${toggleModalFormORValidation.data &&
					toggleModalFormORValidation.data.fullname}`}
				open={toggleModalFormORValidation.open}
				onCancel={() => {
					setToggleModalFormORValidation({
						open: false,
						data: null,
					});
				}}
				footer={[
					<Button
						className="btn-main-primary outlined"
						key={1}
						onClick={() => {
							setToggleModalFormORValidation({
								open: false,
								data: null,
							});
							form.resetFields();
						}}
					>
						CANCEL
					</Button>,
					<Button
						className="btn-main-primary"
						type="primary"
						key={2}
						onClick={() => {
							setToggleModalFormORValidation({ open: false });

							form.validateFields().then((formValues) => {
								let values = {
									...formValues,
									id: toggleModalFormORValidation.data.id,
									or_number: toggleModalFormORValidation.data.or_number,
								};

								setToggleModalAuthPassword({
									open: true,
									data: values,
									from: "Payment Approval List View",
									tableFilter: toggleModalFormORValidation.tableFilter,
								});
							});
						}}
						loading={isLoadingUserEmail}
					>
						SUBMIT
					</Button>,
				]}
			>
				<Form form={form}>
					<Form.Item name="payment_status" rules={[validateRules.required]}>
						<FloatSelect
							placeholder="Payment Status"
							label="Payment Status"
							required={true}
							value={
								toggleModalFormORValidation.data &&
								toggleModalFormORValidation.data.payment_status
							}
							options={[
								{ value: "Not Paid", label: "Not Paid" },
								{ value: "Paid", label: "Paid" },
							]}
						/>
					</Form.Item>

					<Form.Item name="payment_notes">
						<FloatTextArea placeholder="Notes" label="Notes" />
					</Form.Item>
				</Form>
			</Modal>

			<ModalAuthPassword
				toggleModalAuthPassword={toggleModalAuthPassword}
				setToggleModalAuthPassword={setToggleModalAuthPassword}
				refetchStudentExam={refetchStudentExam}
			/>
		</>
	);
}
