import { Row, Col, Card, Flex, Button } from "antd";
import { faArrowRight, faUser } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export function CustomerCard() {
	return (
		<Row gutter={[12, 12]}>
			<Col xs={24} sm={24} md={24} lg={24} xl={16} xxl={16}>
				<Flex>
					<Card
						hoverable
						style={{
							width: 300,
						}}
						href="#"
						className="dashboard-card"
					>
						<div className="top-corner">
							<div className="nav-arrow">
								<FontAwesomeIcon icon={faArrowRight} />
							</div>
						</div>
					</Card>

					<Card
						hoverable
						style={{
							width: 300,
						}}
						href="#"
						className="dashboard-card"
					>
						<div className="top-corner">
							<div className="nav-arrow">
								<FontAwesomeIcon icon={faArrowRight} />
							</div>
						</div>
					</Card>

					<Card
						hoverable
						style={{
							width: 300,
						}}
						href="#"
						className="dashboard-card"
					>
						<div className="top-corner">
							<div className="nav-arrow">
								<FontAwesomeIcon icon={faArrowRight} />
							</div>
						</div>
					</Card>
				</Flex>
			</Col>
		</Row>
	);
}

export function CustomerCardV2() {
	return (
		<Flex gap={12}>
			<Button
				className="btn draw-border br-0 glass"
				// onClick={() => navigate("/profiling-portal/edit")}
			>
				<Flex justify="center" vertical gap={10}>
					<FontAwesomeIcon icon={faUser} style={{ fontSize: "50px" }} />
					<span>Returning Applicant</span>
				</Flex>
			</Button>

			<Button
				className="btn draw-border br-0 glass"
				// onClick={() => navigate("/profiling-portal/edit")}
			>
				<Flex justify="center" vertical gap={10}>
					<FontAwesomeIcon icon={faUser} style={{ fontSize: "50px" }} />
					<span>Returning Applicant</span>
				</Flex>
			</Button>
		</Flex>
	);
}
