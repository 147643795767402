import { useState, useEffect } from "react";
import { Col, Row, Form, Radio, Typography, notification, Button } from "antd";

import dayjs from "dayjs";
import FloatInput from "../../../../providers/FloatInput";
import FloatDatePicker from "../../../../providers/FloatDatePicker";
import FloatSelect from "../../../../providers/FloatSelect";
import validateRules from "../../../../providers/validateRules";
import notificationErrors from "../../../../providers/notificationErrors";
import ModalAuthPassword from "../components/ModalAuthPassword";
import { GET, POST } from "../../../../providers/useAxiosQuery";

export default function ApplicantFormAcademicProfile(props) {
	const { formDisabled, profile_id } = props;

	const [form] = Form.useForm();
	const [formData, setFormData] = useState(null);

	const [toggleModalAuthPassword, setToggleModalAuthPassword] = useState({
		open: false,
		data: null,
	});

	const [studentlevel, setStudentLevel] = useState(null);

	const handleStudentStatus = (checkedValues) => {
		// handleDebounce(checkedValues, "student_status");
	};

	const handleWorkingStudent = (checkedValues) => {
		// handleDebounce(checkedValues, "working_student");
	};

	const handleAppliedFSUU = (checkedValues) => {
		// handleDebounce(checkedValues, "applied_to_fsuu");
	};

	const { data: dataSchoolName } = GET(`api/school`, "school");

	const { refetch: refetchAcademicProfile } = GET(
		`api/student_exams/${profile_id}`,
		["student_exams_info", "check_user_permission"],
		(res) => {
			if (res.data) {
				let data = res.data;

				let student_status = null;
				let student_level_id = null;

				// Strand
				let student_strand = null;

				// Transferee
				let previous_school_name = null;
				let previous_school_year = null;

				let applied_to_fsuu = null;
				let year_applied = null;
				let accepted_to_fsuu = null;
				let year_accepted = null;
				let attended_to_fsuu = null;
				let year_attended = null;

				// Second Degree
				let intend_to_pursue = null;
				let working_student = null;
				let employer_name = null;
				let employer_address = null;

				if (data.student_academics && data.student_academics.length > 0) {
					// Student Status
					let studentInfo = data.student_academics[0];

					if (studentInfo) {
						student_status = studentInfo.student_status;

						if (student_status === "Transferee") {
							previous_school_name = studentInfo.previous_school_name;
							previous_school_year = studentInfo.previous_school_year
								? dayjs(studentInfo.previous_school_year)
								: null;

							applied_to_fsuu = studentInfo.applied_to_fsuu;

							year_applied = studentInfo.year_applied
								? dayjs(studentInfo.year_applied)
								: null;

							accepted_to_fsuu = studentInfo.accepted_to_fsuu;
							year_accepted = studentInfo.year_accepted
								? dayjs(studentInfo.year_accepted)
								: null;

							attended_to_fsuu = studentInfo.attended_to_fsuu;

							year_attended = studentInfo.year_attended
								? dayjs(studentInfo.year_attended)
								: null;
						} else if (student_status === "Pursuing a Second Degree") {
							intend_to_pursue = studentInfo.intend_to_pursue;
							working_student = studentInfo.working_student;
							employer_name = studentInfo.employer_name;
							employer_address = studentInfo.employer_address;
						}

						setStudentLevel(studentInfo.student_level_id);

						// Strand
						student_strand = studentInfo.student_strand;
					}
				}

				form.setFieldsValue({
					student_status,
					student_level_id,

					previous_school_name,
					previous_school_year,

					applied_to_fsuu,
					year_applied: year_applied,
					accepted_to_fsuu,
					year_accepted: year_accepted,
					attended_to_fsuu,
					year_attended: year_attended,

					intend_to_pursue,
					working_student,
					employer_name,
					employer_address,

					student_strand,
				});
			}
		},
		false
	);

	useEffect(() => {
		refetchAcademicProfile();
	}, [profile_id, refetchAcademicProfile]);

	const {
		mutate: mutateAcademicProfile,
		isLoading: isLoadingUpdateChanges,
	} = POST(`api/update_academic_profile`, "student_exams_list");

	const onFinish = (auth_password) => {
		let values = formData;

		let previous_school_year = values.previous_school_year
			? dayjs(values.previous_school_year, "YYYY").format("YYYY")
			: null;

		let year_applied = values.year_applied
			? dayjs(values.year_applied, "YYYY").format("YYYY")
			: null;

		let year_accepted = values.year_accepted
			? dayjs(values.year_accepted, "YYYY").format("YYYY")
			: null;

		let year_attended = values.year_attended
			? dayjs(values.year_attended, "YYYY").format("YYYY")
			: null;

		let data = {
			...values,
			previous_school_year: previous_school_year,
			year_applied: year_applied,
			year_accepted: year_accepted,
			year_attended: year_attended,
			profile_id: parseInt(profile_id, 10),

			// Auth Password Address for Changes
			auth_password: auth_password,
		};

		// console.log("values academic: ", values);

		let data2 = new FormData();

		mutateAcademicProfile(data, {
			onSuccess: (res) => {
				notification.success({
					message: "Academic Profile Updated",
					description: res.message,
				});
				setToggleModalAuthPassword({
					open: false,
					data: null,
				});
			},
			onError: (err) => {
				notificationErrors(err, form);
			},
		});
	};

	return (
		<Form form={form} onFinish={onFinish}>
			<Row gutter={[12, 0]}>
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<p>Which category best describes your current student status?</p>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					{/* STUDENT STATUS */}
					<Form.Item name="student_status">
						<Radio.Group onChange={handleStudentStatus}>
							<Radio value="New Student">New Student</Radio>
							<Radio value="Transferee">Transferee</Radio>
							<Radio value="Returnee">Returnee</Radio>
							<Radio value="Pursuing a Second Degree">
								Pursuing a Second Degree
							</Radio>
						</Radio.Group>
					</Form.Item>

					{/* Show if student status === Transferee  */}
					<Form.Item shouldUpdate noStyle>
						{() => {
							let show = false;
							let student_status = form.getFieldValue("student_status");

							if (student_status === "Transferee") {
								return (
									<>
										<Col xs={24} sm={24} md={24} lg={24} xl={24}>
											<Col
												xs={24}
												sm={24}
												md={24}
												lg={24}
												xl={24}
												className="p-0"
											>
												<Typography.Title
													level={5}
													type="danger"
													className="mt-0"
												>
													*For Transferee Only
												</Typography.Title>
											</Col>

											<Col
												xs={24}
												sm={24}
												md={24}
												lg={24}
												xl={24}
												className="p-0"
											>
												<p className="mt-0">
													Please list all universities and/ or colleges you have
													attended and year attended. Failure to disclose
													previous attendance at another institution could
													result in academic dismissal.
												</p>
											</Col>

											<Row gutter={[12, 0]}>
												<Col xs={24} sm={24} md={12} lg={12} xl={12}>
													<Form.Item
														name={["previous_school_name"]}
														rules={[validateRules.quillValidator]}
													>
														<FloatSelect
															label="Name of School"
															placeholder="Name of School"
															allowClear
															required={true}
															options={
																dataSchoolName && dataSchoolName.data
																	? dataSchoolName.data
																			.filter(
																				(item) => item.school_level_id === 5
																			)
																			.map((item) => ({
																				value: item.school_name,
																				label: item.school_name,
																			}))
																			.sort((a, b) =>
																				a.label.localeCompare(b.label)
																			)
																	: []
															}
														/>
													</Form.Item>
												</Col>

												{/* Year Attended */}
												<Col xs={24} sm={12} md={12} lg={4} xl={4}>
													<Form.Item
														name={["previous_school_year"]}
														rules={[validateRules.quillValidator]}
													>
														<FloatDatePicker
															label="Year Attended"
															placeholder="Year Attended"
															format="YYYY"
															picker="year"
															allowClear={true}
															required={true}
														/>
													</Form.Item>
												</Col>
											</Row>

											<Col className="p-0">
												<p className="mt-0">
													Have you ever applied to FATHER SATURNINO URIOS
													UNIVERSITY before?
												</p>
											</Col>

											<Col xs={24} sm={24} md={24} lg={24} xl={24}>
												<Row gutter={[12, 0]}>
													<Col xs={24} sm={24} md={12} lg={12} xl={12}>
														<Form.Item name="applied_to_fsuu">
															<Radio.Group>
																<Radio value="Yes">Yes</Radio>
																<Radio value="No">No</Radio>
															</Radio.Group>
														</Form.Item>
													</Col>

													<Form.Item shouldUpdate noStyle>
														{() => {
															let applied_to_fsuu = form.getFieldValue(
																"applied_to_fsuu"
															);

															if (applied_to_fsuu === "Yes") {
																return (
																	<>
																		<Col xs={24} sm={24} md={4} lg={4} xl={4}>
																			<Form.Item
																				name="year_applied"
																				rules={[validateRules.quillValidator]}
																			>
																				<FloatDatePicker
																					label="Year Applied"
																					placeholder="Year Applied"
																					format="YYYY"
																					picker="year"
																					allowClear={true}
																				/>
																			</Form.Item>
																		</Col>

																		<Col
																			xs={24}
																			sm={24}
																			md={12}
																			lg={12}
																			xl={12}
																			className="p-0"
																		>
																			<p className="mt-0">Were you Accepted?</p>
																		</Col>

																		<Col
																			xs={24}
																			sm={24}
																			md={24}
																			lg={24}
																			xl={24}
																		>
																			<Row gutter={[12, 0]}>
																				<Col
																					xs={24}
																					sm={24}
																					md={12}
																					lg={12}
																					xl={12}
																				>
																					<Form.Item name="accepted_to_fsuu">
																						<Radio.Group>
																							<Radio value="Yes">Yes</Radio>
																							<Radio value="No">No</Radio>
																						</Radio.Group>
																					</Form.Item>
																				</Col>
																				<Form.Item shouldUpdate noStyle>
																					{() => {
																						let accepted_to_fsuu = form.getFieldValue(
																							"accepted_to_fsuu"
																						);

																						if (accepted_to_fsuu === "Yes") {
																							return (
																								<>
																									<Col
																										xs={24}
																										sm={24}
																										md={4}
																										lg={4}
																										xl={4}
																									>
																										<Form.Item
																											name="year_accepted"
																											rules={[
																												validateRules.quillValidator,
																											]}
																										>
																											<FloatDatePicker
																												label="Year Accepted"
																												placeholder="Year Accepted"
																												format="YYYY"
																												picker="year"
																												allowClear={true}
																											/>
																										</Form.Item>
																									</Col>
																								</>
																							);
																						}
																					}}
																				</Form.Item>
																			</Row>
																		</Col>

																		<Col
																			xs={24}
																			sm={24}
																			md={24}
																			lg={24}
																			xl={24}
																			className="mt-0"
																		>
																			<p className="mt-0">Did you attend?</p>
																		</Col>

																		<Col
																			xs={24}
																			sm={24}
																			md={24}
																			lg={24}
																			xl={24}
																		>
																			<Row gutter={[12, 0]}>
																				<Col
																					xs={24}
																					sm={24}
																					md={12}
																					lg={12}
																					xl={12}
																				>
																					<Form.Item
																						name="attended_to_fsuu"
																						className="mb-0"
																					>
																						<Radio.Group>
																							<Radio value="Yes">Yes</Radio>
																							<Radio value="No">No</Radio>
																						</Radio.Group>
																					</Form.Item>
																				</Col>

																				<Form.Item shouldUpdate noStyle>
																					{() => {
																						let attended_to_fsuu = form.getFieldValue(
																							"attended_to_fsuu"
																						);

																						if (attended_to_fsuu === "Yes") {
																							return (
																								<>
																									{" "}
																									<Col
																										xs={24}
																										sm={24}
																										md={4}
																										lg={4}
																										xl={4}
																									>
																										<Form.Item
																											name="year_attended"
																											rules={[
																												validateRules.quillValidator,
																											]}
																										>
																											<FloatDatePicker
																												label="Year Attended"
																												placeholder="Year Attended"
																												format="YYYY"
																												picker="year"
																												allowClear={true}
																											/>
																										</Form.Item>
																									</Col>
																								</>
																							);
																						}
																					}}
																				</Form.Item>
																			</Row>
																		</Col>
																	</>
																);
															} else {
																return null;
															}
														}}
													</Form.Item>
												</Row>
											</Col>
										</Col>
									</>
								);
							}
						}}
					</Form.Item>

					{/* Show if student_status === Pursuing a Second Degree  */}
					<Form.Item shouldUpdate noStyle>
						{() => {
							let show = false;
							let student_status = form.getFieldValue("student_status");

							if (student_status === "Pursuing a Second Degree") {
								return (
									<>
										<Col
											xs={24}
											sm={24}
											md={24}
											lg={24}
											xl={24}
											className="p-0"
										>
											<Typography.Title
												level={4}
												type="danger"
												className="mt-0"
											>
												*For Second Course Only (Graduate in 2 or 4/5 year
												course)
											</Typography.Title>
										</Col>

										<Col xs={24} sm={24} md={24} lg={24} xl={24}>
											<p className="mt-0">
												What degree program do you intend to pursue?
											</p>
										</Col>

										<Col xs={24} sm={24} md={24} lg={24} xl={24}>
											<Form.Item name="intend_to_pursue">
												<Radio.Group>
													<Radio value="Graduate">Graduate</Radio>
													<Radio value="Baccalaureate">Baccalaureate</Radio>
													<Radio value="Law">Law</Radio>
													<Radio value="Tech-Voc">Tech-Voc</Radio>
												</Radio.Group>
											</Form.Item>
										</Col>

										<Col xs={24} sm={24} md={24} lg={24} xl={24}>
											<p className="mt-0">Are you a working student?</p>
										</Col>

										<Col xs={24} sm={24} md={24} lg={24} xl={24}>
											<Form.Item name="working_student">
												<Radio.Group onChange={handleWorkingStudent}>
													<Radio value="Yes">Yes</Radio>
													<Radio value="No">No</Radio>
												</Radio.Group>
											</Form.Item>

											<Form.Item shouldUpdate noStyle>
												{() => {
													let show = false;
													let working_student = form.getFieldValue(
														"working_student"
													);

													if (working_student === "Yes") {
														return (
															<>
																<p className="mt-0">
																	If yes, give name and address of most recent
																	employer.
																</p>

																<Col xs={24} sm={24} md={12} lg={6} xl={6}>
																	<Form.Item
																		name="employer_name"
																		rules={[
																			validateRules.quillValidator,
																			validateRules.alphabet_symbol,
																			validateRules.trim,
																			{
																				min: 10,
																				message:
																					"Please enter at least 10 characters",
																			},
																		]}
																	>
																		<FloatInput
																			label="Employer Fullname"
																			placeholder="Employer Fullname"
																			required={true}
																		/>
																	</Form.Item>
																</Col>

																<Col xs={24} sm={24} md={12} lg={6} xl={6}>
																	<Form.Item
																		name="employer_address"
																		rules={[
																			validateRules.quillValidator,
																			validateRules.address,
																			validateRules.trim,
																		]}
																	>
																		<FloatInput
																			label="Employer Address"
																			placeholder="Employer Address"
																			required={true}
																		/>
																	</Form.Item>
																</Col>
															</>
														);
													}
												}}
											</Form.Item>
										</Col>
									</>
								);
							}
						}}
					</Form.Item>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Button
						type="primary"
						className="btn-main-primary mb-20 w-xs-100"
						onClick={() => {
							setFormData(form.getFieldsValue());
							setToggleModalAuthPassword({
								open: true,
							});
						}}
					>
						Change
					</Button>
				</Col>
			</Row>
			{/* Modal */}
			<ModalAuthPassword
				toggleModalAuthPassword={toggleModalAuthPassword}
				setToggleModalAuthPassword={setToggleModalAuthPassword}
				onFinish2={onFinish}
				profile_id={profile_id}
				isLoadingUpdateChanges={isLoadingUpdateChanges}
			/>
		</Form>
	);
}
